import React from 'react'
import { connect } from 'react-redux'
import TopBar from '../../components/TopBar'
import Footer from '../../components/Footer'
import BlockFaqs from './BlockFaqs'
import withScrollTop from '../../hocs/ScrollTop'
import { loadFaqs, unloadFaqs, getFaqs } from '../../state/faqs'

class Faq extends React.Component {

  componentDidMount(){
    this.props.loadFaqs()
  }

  componentWillUnmount(){
    this.props.unloadFaqs()
  }

  render(){
    const faqs = this.props.faqs
    return (
      <div className='main-text'>
        <div className='bg-green-gradient pb-4'>
          <TopBar />
          <div className='bg-white pl-4 pt-4 min-page-height'>
            <BlockFaqs faqs={faqs} />
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default withScrollTop(
  connect(
    state => ({
      faqs: getFaqs(state)
    }),
    {
      loadFaqs, unloadFaqs
    },
  )(Faq)
)
