import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink as NormalNavLink,
 } from 'reactstrap';
import './TopBar.scss'

class TopBar extends React.Component {
  state = {
    isOpen: false,
  }

  toggle = () => this.setState({
    isOpen: !this.state.isOpen
  });

  render(){
    const { isOpen } = this.state
    const { color } = this.props
    return (
      <Navbar className='navbar-wroom' expand="md" color={color} light>
        <NavbarBrand tag={Link} to='/' className='ml-md-4'>
          <img src="/images/logo-wroom.svg" alt="logo wroom" className="top-logo"/>
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar className='flex-1 justify-content-center'>
            <NavItem>
              <NavLink className='nav-link pr-3' exact to="/">Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className='nav-link pr-3' to="/mappa">Mappa</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className='nav-link pr-3' to="/convenzionati">Convenzionati</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className='nav-link pr-3' to="/promozioni">Promozioni</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className='nav-link pr-3' to="/eventi">Eventi</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className='nav-link pr-3' to="/faq">Faq</NavLink>
            </NavItem>
            <NavItem>
              <NormalNavLink className='nav-link pr-3' target='_blank' href="https://academy.wroom.org/">Academy</NormalNavLink>
            </NavItem>
            <NavItem>
              <NavLink className='nav-link' to="/contatti">Contatti</NavLink>
            </NavItem>
            <NavItem>
              <NormalNavLink className='nav-link pr-3' target='_blank' href="https://wroomblog.blogspot.com/">Blog</NormalNavLink>
            </NavItem>
          </Nav>
          <Nav navbar className='ml-auto mr-md-4' style={{width: 150, display: 'flex', justifyContent: 'flex-end'}}>
            <NavItem>
              <NormalNavLink className='nav-link' href="https://www.facebook.com/WROOMAPP/" target="_blank">
                <img src='/images/facebook.png' alt='Facebook' className="social-icon" />
              </NormalNavLink>
            </NavItem>
            <NavItem>
              <NormalNavLink className='nav-link' target="_blank" href="https://www.instagram.com/wroom_guidailfuturo/?hl=it">
                <img src='/images/instagram.png' alt='instagram' className="social-icon"/>
              </NormalNavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}

TopBar.defaultProps = {
  color: 'transparent',
}

export default TopBar
