import React from 'react'
import {
  UncontrolledCarousel,
} from 'reactstrap'
import './BlockGuideCharger.scss'

const items = [
  {
    src: '/images/guide-charger/guide-charger-1.png',
    altText: 'Guide charger',
    caption: '',
  },
  {
    src: '/images/guide-charger/guide-charger-2.png',
    altText: 'Guide charger',
    caption: '',
  },
  {
    src: '/images/guide-charger/guide-charger-3.png',
    altText: 'Guide charger',
    caption: '',
  },
  {
    src: '/images/guide-charger/guide-charger-4.png',
    altText: 'Guide charger',
    caption: '',
  },
  {
    src: '/images/guide-charger/guide-charger-5.png',
    altText: 'Guide charger',
    caption: '',
  },
]

class BlockGuideCharger extends React.Component {
  render(){
    return (
      <div className='block-guide-charger container pt-4 pb-4'>
        <div className='row'>
          <div className='col-md-6 text-center pt-4'>
            <div className='main-text text-white pt-4 pb-4'>
              <b>WROOM ti guida passo dopo passo<br />
              durante tutta la ricarica</b>
            </div>
            <div className='pt-4 main-text text-white'>
              <p>Ogni volta che ricarichi WROOM ti segue passo passo<br/> durante tutto il processo di ricarica<br /> aiutandoti a portare a termine con successo<br /> l’operazione.</p>
              <p>Ti sei dimenticato di aver lasciato la macchina in <br />ricarica?</p>
              <p>Nessun problema alla fine di ogni ricarica WROOM<br /> ti avverte mandandoti una notifica push sul tuo <br />smartphone.</p>
            </div>
          </div>
          <div className='col-md-6 text-center align-items-center d-flex'>
            <UncontrolledCarousel
              className='charger-carousel'
              items={items}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default BlockGuideCharger
