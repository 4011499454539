import request from 'superagent'
import { rj } from 'redux-rocketjump'
import combineRjs from 'redux-rocketjump/plugins/combine'
import posArgs from 'redux-rocketjump/plugins/positionalArgs'
import { MASTER_TOKEN, API_URL } from '../constants'

const config = {}

const GET_NEWS = 'GET_NEWS'
config.list = rj({
  type: GET_NEWS,
  api: (params) =>
    request.get(`${API_URL}/news`)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .query(params)
      .then(({ body }) => body)
})

const GET_NEW = 'GET_NEW'
config.detail = rj(posArgs(), {
  type: GET_NEW,
  api: (id) =>
    request.get(`${API_URL}/news/${id}`)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .then(({ body }) => body)
})



export const {
  connect: {
    list: {
      actions: {
        load: loadNews,
        unload: unloadNews,
      },
      selectors: {
        getData: getNews,
      }
    },
    detail: {
      actions: {
        load: loadNew,
        unload: unloadNew,
      },
      selectors: {
        getData: getNew,
        getError: getNewError,
      }
    }
  },
  saga,
  reducer,
} = combineRjs(config, {
  state: 'news',
})
