import request from 'superagent'
import { rj } from 'redux-rocketjump'
import combineRjs from 'redux-rocketjump/plugins/combine'
import uniqBy from 'lodash/uniqBy'
import { MASTER_TOKEN, API_URL } from '../constants'

const config = {}

const GET_GEO_STATIONS = 'GET_GEO_STATIONS'
config.geo = rj({
  type: GET_GEO_STATIONS,
  api: ({ lat, long, dlat, dlong }) =>
    request.get(`${API_URL}/geostations/${lat}/${long}/${dlat}/${dlong}?cluster=1`)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .then(({ body }) => uniqBy(body,'_id'))
})

const LIMIT = 7
const GET_SEARCH_STATIONS = 'GET_SEARCH_STATIONS'
config.search = rj({
  type: GET_SEARCH_STATIONS,
  api: ({ q }) =>
    request.get(`${API_URL}/stations?name.$contains=${q}&limit=${LIMIT}`)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .then(({ body }) => body.slice(0, LIMIT))
})

export const {
  connect: {
    geo: {
      actions: {
        load: loadGeoStations,
        unload: unloadGeoStations,
      },
      selectors: {
        getData: getGeoStations,
      }
    },
    search: {
      actions: {
        load: searchStations,
        unload: unloadSearchStations,
      },
      selectors: {
        getData: getSearchStations,
      }
    }
  },
  saga,
  reducer,
} = combineRjs(config, {
  state: 'stations',
})
