import React from 'react'
import Slider from "react-slick"
import PromotionModal from '../PromotionModal'
import './BlockPromotionsEvidence.scss'

class BlockPromotionsEvidence extends React.Component {

  state = {
    isOpenPromotionModal : false,
    promotion: null
  }

  handlePromotion = (promotion) => {
    this.setState({ isOpenPromotionModal: true, promotion: promotion})
  }

  togglePromotionModal = () => {
    this.setState({ isOpenPromotionModal: !this.state.isOpenPromotionModal})
  }

  render(){
    const promotions = this.props.promotions

    const fixSliderBugValue = promotions.length < 3 ? promotions.length : 3
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: fixSliderBugValue,
      slidesToScroll: 2,
      initialSlide: 0,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: fixSliderBugValue,
            infinite: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div className='block-promotions-evidence pb-4 bg-white main-text'>
          <div className='text-green-gradient pb-2 text-center'>
            <b>Promozioni in evidenza</b>
          </div>
          <div className='container'>
            <div className='row'>
              <div className='col-md-10 offset-md-1 text-center'>
                <Slider {...settings}>
                  {promotions && promotions.map(promotion =>
                  <div key={promotion.id}>
                    <div
                      className='promotion-block d-flex pointer'
                      onClick={() => this.handlePromotion(promotion)}
                      style={{backgroundImage: `url(${promotion.mainPicture ?
                        promotion.mainPicture.thumbnail.medium
                        : '/images/no_image_available.jpeg'})`}}
                      >
                      <div className="text-white text-center caption">
                        <div><b>{promotion.name}</b></div>
                        <div className='text-center text-white pt-2'>
                          {promotion.description}
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                </Slider>
              </div>
            </div>
          </div>

          {this.state.isOpenPromotionModal &&
          <PromotionModal
            toggle={this.togglePromotionModal}
            isOpen={this.state.isOpenPromotionModal}
            promotion={this.state.promotion}
          /> }
      </div>
    )
  }
}

export default BlockPromotionsEvidence
