export const API_URL = process.env.NODE_ENV === 'production'
  ? 'https://wroom-prod.herokuapp.com'
  : 'https://wroom-dev.herokuapp.com'
export const MASTER_TOKEN = process.env.NODE_ENV === 'production' ? 'f2H1YWAFqWBCCVtcCiTRBg' : 'yimidHMrrrPGQKFeFSPtfA'

export const MAPS_API_KEY = 'AIzaSyC96I2j-YMZhJ2b-f151dY9nIbHGPCCFM0'
export const DEFAULT_MAP_CENTER = { lat: 45.703858 , lng: 9.6919158 }
export const DEFAULT_MAP_ZOOM = 9
export const DEFAULT_DISTANCE = 1000000

export const ICONS_STATIONS = {
  'under-maintenance': '/images/icons/map-pointer-maintenance-offline.svg',
  'busy': '/images/icons/map-pointer-busy.svg',
  'offline': '/images/icons/map-pointer-maintenance-offline.svg',
  'notWroom': '/images/icons/map-pointer-notWroom.svg',
  'online': '/images/icons/map-pointer-online.svg'
}

export const TAG_ICONS = {
  'animal': '/images/icons-partners/animal.png',
  'car': '/images/icons-partners/car.png',
  'clothes': '/images/icons-partners/clothes.png',
  'culture': '/images/icons-partners/culture.png',
  'electronics': '/images/icons-partners/electronics.png',
  'food': '/images/icons-partners/food.png',
  'grocerystore': '/images/icons-partners/grocery-store.png',
  'healt': '/images/icons-partners/healt.png',
  'hotel': '/images/icons-partners/hotel.png',
  'immovable': '/images/icons-partners/immovable.png',
  'installation': '/images/icons-partners/installation.png',
  'jewelery': '/images/icons-partners/jewelery.png',
  'maintenance': '/images/icons-partners/maintenance.png',
  'music': '/images/icons-partners/music.png',
  'sport': '/images/icons-partners/sport.png',
  'transport': '/images/icons-partners/transport.png',
  'no-category': '/images/icons-partners/no-category-activity.png'
}

export const TAG_ICONS_FILTERS = [
  {
    'icon': '/images/icons-partners/clothes.png',
    'value': 'clothes',
    'label': 'Abbigliamento',
  },
  {
    'icon': '/images/icons-partners/animal.png',
    'value': 'animal',
    'label': 'Animali',
  },
  {
    'icon': '/images/icons-partners/food.png',
    'value': 'food',
    'label': 'Cibo',
  },
  {
    'icon': '/images/icons-partners/culture.png',
    'value': 'culture',
    'label': 'Cultura',
  },
  {
    'icon': '/images/icons-partners/electronics.png',
    'value': 'eletronics',
    'label': 'Elettronica',
  },
  {
    'icon': '/images/icons-partners/grocery-store.png',
    'value': 'grocery-store',
    'label': 'Fruttivendolo',
  },
  {
    'icon': '/images/icons-partners/jewelery.png',
    'value': 'jewelery',
    'label': 'Gioielleria',
  },
  {
    'icon': '/images/icons-partners/hotel.png',
    'value': 'hotel',
    'label': 'Hotel',
  },
  {
    'icon': '/images/icons-partners/immovable.png',
    'value': 'immovable',
    'label': 'Immobili',
  },
  {
    'icon': '/images/icons-partners/installations.png',
    'value': 'installations',
    'label': 'Installazioni',
  },
  {
    'icon': '/images/icons-partners/maintenance.png',
    'value': 'maintenance',
    'label': 'In manutenzione',
  },
  {
    'icon': '/images/icons-partners/car.png',
    'value': 'car',
    'label': 'Macchine',
  },
  {
    'icon': '/images/icons-partners/music.png',
    'value': 'music',
    'label': 'Musica',
  },
  {
    'icon': '/images/icons-partners/health.png',
    'value': 'healt',
    'label': 'Salute',
  },
  {
    'icon': '/images/icons-partners/sport.png',
    'value': 'sport',
    'label': 'Sport',
  },
  {
    'icon': '/images/icons-partners/transport.png',
    'value': 'transport',
    'label': 'Trasporti',
  },
]
