import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import './ExhibitorModal.scss'

class ExhibitorModal extends React.Component {
  render(){
    const { toggle, exhibitor, isOpen} = this.props
    console.log(exhibitor)
    return (
      <Modal isOpen={isOpen} className='ExhibitorModal main-text' toggle={toggle} size={'lg'}>
        <ModalBody>
          {exhibitor &&
            <div>
              <div className='text-left pointer' onClick={toggle}>
                <img src='/images/close-modal.png' width={60} alt='Close Modal' />
              </div>
              <div className='text-center'>
                <img src={exhibitor.logo ?
                  exhibitor.logo.thumbnail.squared
                  : '/images/no_image_available.jpeg'}
                  alt={exhibitor.title}
                  className='rounded-circle img-exhibitor'
                  width={230}
                />
              </div>
              <div className='text-center mt-4'>
                <b>{exhibitor.title}</b><br />
              </div>
              <div className='text-center description-exhibitor mt-4'>
                {exhibitor.description}
              </div>

              <div className='text-center mt-4'>
                <a href={exhibitor.link} target='_blank' rel='noopener noreferrer' className='btn button-gradient'>Vai al sito</a>
              </div>

            </div>
          }
        </ModalBody>
      </Modal>
    )
  }
}

export default ExhibitorModal
