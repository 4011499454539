import React from 'react'
import TopBar from '../../components/TopBar'
import Footer from '../../components/Footer'
import BlockFormContact from './BlockFormContact'
import MapWroom from '../../components/MapWroom'
import BoxOblique from '../../components/BoxOblique'
import './Contatti.scss'
import icon from './map-pointer.svg'
import withScrollTop from '../../hocs/ScrollTop'

const MAIN_MAP_CENTER = { lat: 45.703858, lng: 9.6919158 }

const MARKERS = [
  {
    _id: 23,
    icon: icon,
    title: 'Ressolar',
    description: 'Via Carlo Serassi, Bergamo, BG',
    position: MAIN_MAP_CENTER,
  }
]


class Contatti extends React.Component {
  render() {
    return (
      <div className='Contatti main-text'>
        <div className='bg-green-gradient pb-4'>
          <TopBar />
          <div className='container d-flex justify-content-around text-white pt-4 pb-4'>
            <div className='text-center'>
              <i className="fas fa-2x fa-home"></i><br />
              Indirizzo: <br /><br />
              Via Carlo Serassi, 21<br />
              24124 Bergamo, BG
            </div>
            <div className='text-center'>
              <i className="fas fa-2x fa-envelope"></i><br />
              Indirizzo e-mail: <br /><br />
              <a className='text-white' href='mailto:info@wroom.org'>info@wroom.org</a>
            </div>
            <div className='text-center'>
              <i className="fas fa-2x fa-phone"></i><br />
              Telefono: <br /><br />
              +39 035 36.92.145
            </div>
          </div>
          <div className="w-100 position-relative" style={{ height:400 }}>
            <div className='position-absolute bg-white rounded main-text shadow-lg block-opening-hours'>
              <div className='pt-4 pl-4'>
              <h5>Orari di apertura</h5>
              </div>
              <div className='table-responsive pl-3'>
                <table className='table table-borderless'>
                  <tbody>
                    <tr>
                      <td>Lunedì</td>
                      <td className='text-green-gradient'>9:30 - 17:30</td>
                    </tr>
                    <tr>
                      <td>Martedì</td>
                      <td className='text-green-gradient'>9:30 - 17:30</td>
                    </tr>
                    <tr>
                      <td>Mercoledì</td>
                      <td className='text-green-gradient'>9:30 - 17:30</td>
                    </tr>
                    <tr>
                      <td>Giovedì</td>
                      <td className='text-green-gradient'>9:30 - 17:30</td>
                    </tr>
                    <tr>
                      <td>Venerdì</td>
                      <td className='text-green-gradient'>9:30 - 17:30</td>
                    </tr>
                    <tr>
                      <td>Sabato</td>
                      <td className='text-red'>chiuso</td>
                    </tr>
                    <tr>
                      <td>Domenica</td>
                      <td className='text-red'>chiuso</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <MapWroom
              center={MAIN_MAP_CENTER}
              markers={MARKERS}
            />
          </div>
          <BlockFormContact />
        </div>
        <div className='bg-green-gradient position-relative'>
          <BoxOblique position='top' fill='#00AE84' />
          <Footer />
        </div>
      </div>
    )
  }
}

export default withScrollTop(Contatti)
