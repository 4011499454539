import React, { useEffect } from 'react'
import TopBar from '../../components/TopBar'
import Footer from '../../components/Footer'
import { API_URL, MASTER_TOKEN } from "../../constants";

const AttivazioneUtente = () => {

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const hasSecret = params.has('secret')
    const secret = params.get('secret')

    const activateUser = async () => {
      const response = await fetch(`${API_URL}/users/confirmationSecret/${secret}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${MASTER_TOKEN}`
        }
      })
      const json = await response.json();
      return json
    }

    hasSecret && activateUser()
  }, [])

  return (
    <div className='main-text'>
      <div className='bg-green-gradient pb-4'>
        <TopBar />
        <div className='bg-white pl-4 pt-4' style={{height:200}}>
          Attivazione utente effettuata
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default AttivazioneUtente
