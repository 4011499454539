import React from 'react'
import { connect } from 'react-redux'
import TopBar from '../../components/TopBar'
import BlockAppCarousel from './BlockAppCarousel'
import BlockFunctionalities from './BlockFunctionalities'
import BlockVideo from './BlockVideo'
import BlockBookingCharger from './BlockBookingCharger'
import BlockGuideCharger from './BlockGuideCharger'
import BlockReviews from './BlockReviews'
import BlockGiftCharger from './BlockGiftCharger'
import BlockNewChargers from './BlockNewChargers'
import Footer from '../../components/Footer'
import BoxOblique from '../../components/BoxOblique'
import { loadNews, unloadNews, getNews } from '../../state/news'
import './Home.scss'
import withScrollTop from '../../hocs/ScrollTop'

class Home extends React.Component {

  componentDidMount(){
    this.props.loadNews()
  }

  componentWillUnmount(){
    this.props.unloadNews()
  }

  render() {
    const news = this.props.news
    return (
      <div className='Home'>
        <div className='bg-green-gradient position-relative pb-4'>
          <TopBar />
          <BlockAppCarousel />
          <BoxOblique position='bottom' />
        </div>
        <div>
          <BlockFunctionalities />
        </div>
        <div className='bg-green-gradient position-relative'>    
          <BlockVideo />
        </div>
        <div className='pt-4 mt-4'>
          <BlockBookingCharger />
        </div>
        <div className='bg-green-gradient position-relative pb-4'>
          <BoxOblique position='top' />
          <BlockGuideCharger />
          <BoxOblique position='bottom' />
        </div>
        <div>
          <BlockReviews />
        </div>
        <div className='bg-green-gradient position-relative pb-4'>
          <BoxOblique position='top' />
          <BlockGiftCharger />
          <BoxOblique position='bottom' />
        </div>
        <div>
          <BlockNewChargers news={news} />
        </div>
        <div className='bg-green-gradient position-relative pb-4'>
          <BoxOblique position='top' />
          <Footer />
        </div>

      </div>
    )
  }
}

export default withScrollTop(
  connect(
    state => ({
      news: getNews(state)
    }),
    {
      loadNews, unloadNews
    },
  )(Home)
)
