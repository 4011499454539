import request from 'superagent'
import { rj } from 'redux-rocketjump'
import combineRjs from 'redux-rocketjump/plugins/combine'
import posArgs from 'redux-rocketjump/plugins/positionalArgs'
import { MASTER_TOKEN, API_URL } from '../constants'

const config = {}

const GET_GEO_PARTNERS = 'GET_GEO_PARTNERS'
config.geo = rj({
  type: GET_GEO_PARTNERS,
  api: ({ lat, long, dlat, dlong }) =>
    request.get(`${API_URL}/geopartners/${lat}/${long}/${dlat}/${dlong}?cluster=true`)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .then(({ body }) => body)
})

const GET_PARTNERS = 'GET_PARTNERS'
config.list = rj({
  type: GET_PARTNERS,
  api: (params) =>
    request.get(`${API_URL}/partners`)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .query(params)
      .then(({ body }) => body)
})

const GET_PARTNER = 'GET_PARTNER'
config.detail = rj(posArgs(), {
  type: GET_PARTNER,
  api: (id, token) =>
  {
    if (token) {
      return (request.get(`${API_URL}/previewPartners/${id}`)
        .set('Authorization', `Bearer ${token}`)
        .then(({ body }) => body))
    }
    return (request.get(`${API_URL}/partners/${id}`)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .then(({ body }) => body))
  }
})



export const {
  connect: {
    geo: {
      actions: {
        load: loadGeoPartners,
        unload: unloadGeoPartners,
      },
      selectors: {
        getData: getGeoPartners,
      }
    },
    list: {
      actions: {
        load: loadPartners,
        unload: unloadPartners,
      },
      selectors: {
        getData: getPartners,
      }
    },
    detail: {
      actions: {
        load: loadPartner,
        unload: unloadPartner,
      },
      selectors: {
        getData: getPartner,
        getError: getPartnerError,
      }
    }
  },
  saga,
  reducer,
} = combineRjs(config, {
  state: 'partners',
})
