import React from 'react'
import TopBar from '../../components/TopBar'
import Footer from '../../components/Footer'
import withScrollTop from '../../hocs/ScrollTop'

class Privacy extends React.Component {
  render(){
    return (
      <div className='main-text'>
        <div className='bg-green-gradient pb-4'>
          <TopBar />
          <div className='bg-white pl-4 pt-4' style={{height:200}}>
            Privacy Page
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default withScrollTop(Privacy)
