import React from 'react'
import Bounce from 'react-reveal/Bounce'
import './BlockIntestation.scss'
import presentazione from './../../../document/presentazione_convenzionati.pdf'


class BlockIntestation extends React.Component {

  _downloadPresentation = () => {
    window.open(presentazione, '_blank');
  }
  render(){
    return (
      <div className='container text-center pb-4'>
        <Bounce bottom>
          <div className='text-white main-text pt-4 pb-4'>
            <b>Scopri tutte le attività convenzionate con WROOM.</b>
          </div>
            <button
                className='btn btn-filters btn-md'
                onClick={this._downloadPresentation}
                id="Popover1"
            >
              Diventa Partner
            </button>
        </Bounce>
        <Bounce bottom>
          <div className='text-white main-text pt-3 pb-3'>
          </div>
        </Bounce>
      </div>
    )
  }
}

export default BlockIntestation
