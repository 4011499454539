import request from 'superagent'
import { rj } from 'redux-rocketjump'
import combineRjs from 'redux-rocketjump/plugins/combine'
import posArgs from 'redux-rocketjump/plugins/positionalArgs'
import { MASTER_TOKEN, API_URL } from '../constants'

const config = {}

const GET_FAQS = 'GET_FAQS'
config.list = rj({
  type: GET_FAQS,
  api: (params) =>
    request.get(`${API_URL}/faq/web`)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .query(params)
      .then(({ body }) => body)
})

const GET_FAQ = 'GET_FAQ'
config.detail = rj(posArgs(), {
  type: GET_FAQ,
  api: (id) =>
    request.get(`${API_URL}/faqs/${id}`)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .then(({ body }) => body)
})



export const {
  connect: {
    list: {
      actions: {
        load: loadFaqs,
        unload: unloadFaqs,
      },
      selectors: {
        getData: getFaqs,
      }
    },
    detail: {
      actions: {
        load: loadFaq,
        unload: unloadFaq,
      },
      selectors: {
        getData: getFaq,
        getError: getFaqError,
      }
    }
  },
  saga,
  reducer,
} = combineRjs(config, {
  state: 'faqs',
})
