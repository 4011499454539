import React from 'react'

import {
  Input,
  FormGroup,
  FormFeedback,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from 'reactstrap'

export const InputField = ({
  field,
  form: { touched, errors },
  label,
  addonText,
  addonButton,
  rowLayout = true,
  ...props
}) => {
  let inputElement = (
    <Input
      {...field}
      {...props}
      invalid={touched[field.name] && !!errors[field.name]}
    />
  )

  let feedbackElement = (
    touched[field.name] && errors[field.name] &&
    <FormFeedback className='d-block'>{errors[field.name]}</FormFeedback>
  )

  if (addonText) {
    inputElement = (
      <InputGroup>
        {inputElement}
        <InputGroupAddon addonType="append">
          <InputGroupText className='bg-transparent'>{addonText}</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    )
  }

  if (addonButton) {
    inputElement = (
      <InputGroup>
        {inputElement}
        <InputGroupAddon addonType="append">
          <Button className='btn-light' type='submit'><img src='/images/send-button.png' width='15' alt='Send' /></Button>
        </InputGroupAddon>
      </InputGroup>
    )
  }

  return (
    <FormGroup row={rowLayout}>
      {rowLayout && <Col sm={12}>
        {inputElement}
        {feedbackElement}
      </Col>}
      {!rowLayout && <React.Fragment>
        {inputElement}
        {feedbackElement}
      </React.Fragment>}
    </FormGroup>
  )
}
