import React from 'react'
import { Input } from 'reactstrap'
import debounce from 'lodash/debounce'
import './BlockFilters.scss'

class BlockFilters extends React.Component {

  state = {
    search: ''
  }

  applyFilters = (filters) => {
    this.props.applyFilters({
      'title.$contains': filters.search,
    })
  }

  applyFiltersDebounced = debounce(this.applyFilters, 250)

  handleOnSearchChange = (e) => {
    this.setState({search: e.target.value}, () => {
      this.applyFiltersDebounced(this.state)
    })
  }

  render(){
    return (
      <div className='BlockFilters pt-4 pb-4'>
        <div className='text-white text-center main-text pt-4 pb-4'>
          <b>
            Stai cercando un evento in particolare? <br />
          </b>
        </div>
        <div className='container pt-4'>
          <div className='row'>
            <div className='col-md-4'>
              <Input className='search' onChange={this.handleOnSearchChange} style={{borderRadius: '0.75em'}} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BlockFilters
