import React from 'react'
import Fade from 'react-reveal/Fade'

class BlockFaqs extends React.Component {
  render(){
    const faqs = this.props.faqs
    return (
      <div className='container main-text'>
        <div className='row'>
          { faqs && faqs.map((faq) =>
            <Fade key={faq.id}>
              <div className='col-md-12 pt-4 pb-4'>
                <div className='text-green-gradient'><strong>{faq.question}</strong></div>
                <div className='text-green-gradient'>
                  {faq.answer}
                </div>
              </div>
            </Fade>
          )}
        </div>
      </div>
    )
  }
}

export default BlockFaqs
