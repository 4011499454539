import React from 'react';
import { Router, Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './state'
import Home from './pages/Home'
import Convenzionati from './pages/Convenzionati'
import Mappa from './pages/Mappa'
import Faq from './pages/Faq'
import Contatti from './pages/Contatti'
import DettaglioAttivita from './pages/DettaglioAttivita'
import DettaglioEvento from './pages/DettaglioEvento'
import Promozioni from './pages/Promozioni'
import Eventi from './pages/Eventi'
import NotFound from './pages/NotFound'
import AttivazioneUtente from './pages/AttivazioneUtente'
import EliminaUtente from './pages/EliminaUtente';
import Privacy from './pages/Privacy'
import TerminiCondizioni from './pages/TerminiCondizioni'
import CookiePolicy from './pages/CookiePolicy'
import Cookies from './components/Cookies'
//import ReactGA from 'react-ga'
import createHistory from 'history/createBrowserHistory'

const history = createHistory()

if (process.env.NODE_ENV === 'production') {
  // integrate history \w Google Analytics

  // ReactGA.initialize('UA-48978419-27');
  // history.listen((location, action) => {
  //   ReactGA.set({ page: location.pathname + location.search })
  //   ReactGA.pageview(location.pathname + location.search)
  // })
  // ReactGA.pageview(window.location.pathname + window.location.search);

}

class App extends React.Component {
// test
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <React.Fragment>
          <Switch>

            <Route path="/" exact component={Home} />
            <Route path="/convenzionati" exact component={Convenzionati} />
            <Route path="/mappa" exact component={Mappa} />
            <Route path="/contatti" exact component={Contatti} />
            <Route path="/faq" exact component={Faq} />
            <Route path="/promozioni" exact component={Promozioni} />
            <Route path="/eventi" exact component={Eventi} />
            <Route path="/eventi/:id" exact component={DettaglioEvento} />
            <Route path="/convenzionati/:id/:preview" exact component={DettaglioAttivita} />
            <Route path="/convenzionati/:id" exact component={DettaglioAttivita} />
            <Route path="/privacy" exact component={Privacy} />
            <Route path="/termini-e-condizioni" exact component={TerminiCondizioni} />
            <Route path="/cookie-policy" exact component={CookiePolicy} />
            <Route path="/activate-user" exact component={AttivazioneUtente} />
            <Route path="/delete-user" exact component={EliminaUtente} />
            <Route component={NotFound} />
            {/* <Redirect from="/index.html" to="/" />
            <Redirect from="/index.htm" to="/" /> */}
          </Switch>
          <Cookies/>
          </React.Fragment>
        </Router>

      </Provider>
    )
  }
}

export default App;
