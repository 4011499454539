import React from 'react'
import { connect } from 'react-redux'
import intersection from 'lodash/intersection'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import TopBar from '../../components/TopBar'
import Footer from '../../components/Footer'
import BlockIntestation from './BlockIntestation'
import BlockPromotionsEvidence from './BlockPromotionsEvidence'
import BlockPromotions from './BlockPromotions'
import BlockFilters from './BlockFilters'
import BoxOblique from '../../components/BoxOblique'
import withScrollTop from '../../hocs/ScrollTop'
import { loadPromotions, unloadPromotions, getPromotions } from '../../state/promotions'
import './Promozioni.scss'

class Promozioni extends React.Component {

  state = {
    selectedCategories: {}
  }

  componentDidMount(){
    this.props.loadPromotions()
  }

  getPromotions = (promotionsProp) => {
    if(this.state.selectedCategories){
      if(isEmpty(this.state.selectedCategories)){
        return promotionsProp
      }
      let selectedPartnersKeys = Object.keys(this.state.selectedCategories)
      let promotions = promotionsProp.filter(promotion => {
          const partner = promotion.partner
          const tagsKeys = get(partner, "tagsKeys", [])
          const matchingPartnerTypes = intersection(tagsKeys, selectedPartnersKeys)
          return matchingPartnerTypes.length > 0
        })
      return promotions
    }

    return promotionsProp
  }

  applyFilters = (params) => {
    if(params.selectedCategories){
      this.setState({selectedCategories: params.selectedCategories})
    }
    //this.props.unloadPartners()
    this.props.loadPromotions(params)
  }

  componentWillUnmount(){
    this.props.unloadPromotions()
  }

  render() {
    const promotions = this.getPromotions(this.props.promotions)
    return (
      <div className='Promozioni'>
      <div className='bg-green-gradient pb-4'>
        <div className='position-relative pb-4'>
          <TopBar />
          <BlockIntestation />
          <BoxOblique position='bottom' />
        </div>
        {promotions ?
          <BlockPromotionsEvidence promotions={this.props.promotions} />
        :
          <div className='bg-white' style={{minHeight:280}}></div>
        }
        <div className='position-relative'>
          <BoxOblique position='top' />
          <BlockFilters selectedCategories={this.state.selectedCategories} applyFilters={this.applyFilters} />
          <BlockPromotions promotions={promotions} />
        </div>
      </div>
      <div className='bg-green-gradient position-relative'>
        <BoxOblique position='top' fill='#00AE84' />
        <Footer />
      </div>
      </div>
    )
  }
}

export default withScrollTop(
  connect(
    state => ({
      promotions: getPromotions(state)
    }),
    {
      loadPromotions, unloadPromotions
    },
  )(Promozioni)
)
