import React from 'react'
import Fade from 'react-reveal/Fade';

class BlockBookingCharger extends React.Component {
  render(){
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <Fade>
              <img src='/images/booking-charger.png' className='img-fluid' alt='Booking Charger'/>
            </Fade>
          </div>
          <div className='col-md-6'>
            <Fade>
              <div className='text-center mt-2 mt-md-0'>
                <div>
                  <img src='/images/icon-charger.svg' alt='Icon Charger' />
                </div>
                <div className='text-green-gradient pt-4 pb-4'>
                  <b>Prenotazione ricarica</b>
                </div>
                <div className='pt-4 main-text'>
                  Hai paura di trovare la colonnina di ricarica <br />occupata? <br />
                  Prenota la colonnina e assicurati di trovare la tua<br /> presa libera al tuo arrivo.<br />
                  La prenotazione è gratuita e scade dopo 15 minuti<br /> dall’attivazione.
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    )
  }
}

export default BlockBookingCharger
