import React from 'react'
import Fade from 'react-reveal/Fade'
import './BlockFunctionalities.scss'

const BlockFunctionality = ({ title , description, icon }) =>  (
  <div className='block-functionality pt-2 pb-2 rounded shadow'>
    <div className='pt-1 pb-1'>
      <img src={icon} alt={title} />
    </div>
    <h5>{title}</h5>
    <div>
      {description}
    </div>
  </div>
)

class BlockFunctionalities extends React.Component {
    render(){
        return <div className='container pt-4 pb-4'>
            <div className='row'>
                <div className='col-md-4'>
                  <Fade left>
                    <BlockFunctionality
                    title={'Green dot'}
                    description={<p>
                      Attivala geolocalizzazione così<br />
                      che WROOM® possa avere la tua<br />
                      posizione precisa e facilitare il <br />
                      tuo viaggio.
                    </p>}
                    icon={'/images/icon-green-dot.svg'}
                    />
                  </Fade>
                </div>
                <div className='col-md-4'>
                  <Fade top>
                    <BlockFunctionality
                    title={'Ricerca'}
                    description={<p>
                      Grazie alla ricerca, tu scrivi la zona<br />
                      dove vuoi ricaricare la macchina e<br />
                      WROOM® ti indicherà le smart<br />
                      zone più vicine a quella zona.
                    </p>}
                    icon={'/images/icon-ricerca.svg'}
                    />
                  </Fade>
                </div>
                <div className='col-md-4'>
                  <Fade right>
                    <BlockFunctionality
                    title={'Risultati'}
                    description={<p>
                      Seleziona uno dei risultati più <br />
                      vicini alla zona che hai cercato e<br />
                      fatti indicare il percorso più <br />
                      breve per raggiungerla
                    </p>}
                    icon={'/images/icon-risultati.svg'}
                    />
                  </Fade>
                </div>
              </div>
              <div className='row mt-4'>
                <div className='col-md-4'>
                  <Fade left>
                    <BlockFunctionality
                    title={'Menù'}
                    description={<p>
                      Apri il menù per gestire il tuo<br />
                      profilo, vedere lo storico di tutte<br />
                      le ricariche che hai effettuato e<br />
                      molto altro.
                    </p>}
                    icon={'/images/icon-menu.svg'}
                    />
                  </Fade>
                </div>
                <div className='col-md-4'>
                  <Fade top>
                    <BlockFunctionality
                    title={'Preferiti'}
                    description={<p>
                      Aggiungi le smart zone che ti<br />
                      sono piaciute ai tuoi preferiti così<br />
                      potrai avviare la navigazione in<br />
                      maniera più veloce.
                    </p>}
                    icon={'/images/icon-preferiti.svg'}
                    />
                  </Fade>
                </div>
                <div className='col-md-4'>
                  <Fade right>
                    <BlockFunctionality
                    title={'Distanza'}
                    description={<p>
                      Seleziona uno dei risultati più <br />
                      vicini alla zona che hai cercato e<br />
                      fatti indicare il percorso più <br />
                      breve per raggiungerla
                    </p>}
                    icon={'/images/icon-distanza.svg'}
                    />
                  </Fade>
                </div>
              </div>
        </div>
    }
}

export default BlockFunctionalities
