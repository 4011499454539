import React from 'react'
import { connect } from 'react-redux'
import TopBar from '../../components/TopBar'
import Footer from '../../components/Footer'
import BlockFilters from './BlockFilters'
import BlockEvents from './BlockEvents'
import BoxOblique from '../../components/BoxOblique'
import withScrollTop from '../../hocs/ScrollTop'
import { loadEvents, unloadEvents, getEvents } from '../../state/events'

class Eventi extends React.Component {

  componentDidMount(){
    this.props.loadEvents()
  }

  applyFilters = (params) => {
    //this.props.unloadPartners()
    this.props.loadEvents(params)
  }

  componentWillUnmount(){
    this.props.unloadEvents()
  }

  render() {
    const events = this.props.events
    return (
      <div className='Convenzionati'>
      <div className='bg-green-gradient pb-4'>
        <div className='position-relative pb-4'>
          <TopBar />
          <BlockFilters applyFilters={this.applyFilters} />
          <BlockEvents events={events} />
        </div>
      </div>
      <div className='bg-green-gradient position-relative'>
        <BoxOblique position='top' fill='#00AE84' />
        <Footer />
      </div>
      </div>
    )
  }
}

export default withScrollTop(
  connect(
    state => ({
      events: getEvents(state)
    }),
    {
      loadEvents, unloadEvents
    },
  )(Eventi)
)
