import request from 'superagent'
import { rj } from 'redux-rocketjump'
import rjPromise from 'redux-rocketjump/plugins/promise'
import { MASTER_TOKEN, API_URL } from '../constants'

const NEWSLETTER_REQUEST = 'NEWSLETTER_REQUEST'

export const {
  actions: {
    load: sendNewsletter,
  },
  saga,
} = rj(rjPromise, {
  state: false,
  type: NEWSLETTER_REQUEST,
  api: (values) => request.post(`${API_URL}/newsletters`)
    .set('Authorization', `Bearer ${MASTER_TOKEN}`)
    .send(values)
    .then(({ body }) => body)
})()
