import request from 'superagent'
import { rj } from 'redux-rocketjump'
import combineRjs from 'redux-rocketjump/plugins/combine'
import posArgs from 'redux-rocketjump/plugins/positionalArgs'
import { MASTER_TOKEN, API_URL } from '../constants'

const config = {}

const GET_PROMOTIONS = 'GET_PROMOTIONS'
config.list = rj({
  type: GET_PROMOTIONS,
  api: (params) =>
    request.get(`${API_URL}/promotions`)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .query(params)
      .then(({ body }) => body)
})

const GET_PROMOTION = 'GET_PROMOTION'
config.detail = rj(posArgs(), {
  type: GET_PROMOTION,
  api: (id) =>
    request.get(`${API_URL}/promotions/${id}`)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .then(({ body }) => body)
})



export const {
  connect: {
    list: {
      actions: {
        load: loadPromotions,
        unload: unloadPromotions,
      },
      selectors: {
        getData: getPromotions,
      }
    },
    detail: {
      actions: {
        load: loadPromotion,
        unload: unloadPromotion,
      },
      selectors: {
        getData: getPromotion,
        getError: getPromotionError,
      }
    }
  },
  saga,
  reducer,
} = combineRjs(config, {
  state: 'promotions',
})
