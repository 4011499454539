import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import moment from 'moment'
import './PromotionModal.scss'
import connect from "react-redux/es/connect/connect";
import {getPartner, getPartnerError, loadPartner, unloadPartner} from "../../../state/partners";
import _ from 'lodash';

class PromotionModal extends React.Component {

  componentDidMount() {
    const { promotion} = this.props
    if (_.get(promotion, 'partner.id', null)) {
      this.props.loadPartner(promotion.partner.id)
    }
    // setTimeout( () => {
    //   const { partner}  = this.props;
    //   debugger
    // }, 2000)
  }

  render(){
    const { toggle, promotion, isOpen, partner} = this.props

    function calcDaysPromotion(promotion){
      const today = moment(new Date())
      const promotionDate = moment(promotion.validTo)
      const daysPromotion = today.diff(promotionDate,'days')

      return daysPromotion
    }
    console.log({promotion})
    return (
      <Modal isOpen={isOpen} className='PromotionModal main-text' toggle={toggle} size={'lg'}>
        <ModalBody>
          {promotion &&
            <div>
              <div className='text-left pointer' onClick={toggle}>
                <img src='/images/close-modal.png' width={60} alt='Close Modal' />
              </div>
              <div className='text-center'>
                <img src={promotion.mainPicture ?
                  promotion.mainPicture.thumbnail.medium
                  : '/images/no_image_available.jpeg'}
                  alt={promotion.name}
                  className='rounded-circle img-promotion'
                  width={230}
                />
              </div>
              <div className='text-center mt-4'>
                <b>{promotion.name}</b><br />
                <b>{promotion.discountDescription}</b>
              </div>
              <div className='row mt-4 mb-4'>
                <div className='col-md-6 text-center'>
                  <img src='/images/clock-icon.svg' alt='Clock Icon' />
                  <div className='mt-3'>
                    <b>Promozione scade il:</b>
                    <div className='description-promotion mt-2'>
                      {/*{calcDaysPromotion(promotion)} giorni*/}
                      {moment(promotion.validTo).format('DD-MM-YYYY')}
                    </div>
                  </div>
                </div>
                <div className='col-md-6 text-center'>
                  <img src={_.get(partner, 'mainPicture.thumbnail.medium', '/images/no_image_available.jpeg')} alt='Calendar Icon' className='image-like-icon' />
                  <div className='mt-3'>
                    <b>Partner:</b>
                    <div className='description-promotion mt-2'>
                      {_.get(promotion, 'partner.name', '')}
                    </div>
                  </div>
                </div>
              </div>
              <div className='description-promotion text-center mt-4 pt-4 pb-4 mb-4'>
                {promotion.description}
              </div>
              <div className='text-center mt-4 mb-4'>
                <img src='/images/icon-download.svg' alt='Icona download' />
                <div className='mt-2'> Scarica la App dal tuo store di riferimento</div>
              </div>
              <div className='mt-4 mb-4 text-center'>
                <a href='https://itunes.apple.com/us/app/wroom/id1390504465?mt=8' rel='noopener noreferrer' target='_blank'>
                  <img src='/images/button-apple-store.svg' alt='Apple store app' />
                </a>
                <a href='https://play.google.com/store/apps/details?id=it.ressolar.wroom&hl=it' rel='noopener noreferrer' target='_blank'>
                <img src='/images/button-play-store.svg' className='ml-2' alt='Play store download' />
                </a>
              </div>
            </div>
          }
        </ModalBody>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    partner: getPartner(state),
    error: getPartnerError(state)
  }),
  {
    loadPartner, unloadPartner
  },
)(PromotionModal)

