import React from 'react'
import TopBar from '../../components/TopBar'
import Footer from '../../components/Footer'
import withScrollTop from '../../hocs/ScrollTop'
import Fade from 'react-reveal'

class CookiePolicy extends React.Component {
  render(){
    return (
      <div className='main-text'>
        <div className='bg-green-gradient pb-4'>
          <TopBar />
          <div className='bg-white pl-4 pt-4'>
            <div className='container main-text'>
              <div className='row'>
                <Fade>
                  <div className='col-md-12'>
                    <h3 className='text-green-gradient font-weight-bold'>Cookie Policy di Wroom</h3>
                    <p>
                      I Cookie sono costituiti da porzioni di codice installate all'interno del browser che assistono il Titolare nell’erogazione del Servizio in base alle finalità descritte. Alcune delle finalità di installazione dei Cookie potrebbero, inoltre, necessitare del consenso dell'Utente.
                    </p>
                    <p>
                      Quando l’installazione di Cookies avviene sulla base del consenso, tale consenso può essere revocato liberamente in ogni momento seguendo le istruzioni contenute in questo documento.
                    </p>
                    <h4 className='text-green-gradient font-weight-bold'>Cookie tecnici e di statistica aggregata</h4>
                    <h6 className='text-green-gradient font-weight-bold'>Attività strettamente necessarie al funzionamento</h6>
                    <p>
                      Wroom utilizza Cookie per salvare la sessione dell'Utente e per svolgere altre attività strettamente necessarie al funzionamento di Wroom, ad esempio in relazione alla distribuzione del traffico.
                    </p>
                    <h6 className='text-green-gradient font-weight-bold'>Attività di salvataggio delle preferenze, ottimizzazione e statistica</h6>
                    <p>
                      Wroom utilizza Cookie per salvare le preferenze di navigazione ed ottimizzare l'esperienza di navigazione dell'Utente. Fra questi Cookie rientrano, ad esempio, quelli per impostare la lingua e la valuta o per la gestione di statistiche da parte del Titolare del sito.
                    </p>
                    <h4 className='text-green-gradient font-weight-bold'>Altre tipologie di Cookie o strumenti terzi che potrebbero installarne</h4>
                    <p>
                      Alcuni dei servizi elencati di seguito raccolgono statistiche in forma aggregata ed anonima e potrebbero non richiedere il consenso dell'Utente o potrebbero essere gestiti direttamente dal Titolare – a seconda di quanto descritto – senza l'ausilio di terzi.
                    </p>
                    <p>
                      Qualora fra gli strumenti indicati in seguito fossero presenti servizi gestiti da terzi, questi potrebbero – in aggiunta a quanto specificato ed anche all’insaputa del Titolare – compiere attività di tracciamento dell’Utente. Per informazioni dettagliate in merito, si consiglia di consultare le privacy policy dei servizi elencati.
                    </p>
                    <h6 className='text-green-gradient font-weight-bold'>Statistica</h6>
                    <p>
                      I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell’Utente.
                      <br />
                      Google Analytics (Google Inc.)<br />
                      Google Analytics è un servizio di analisi web fornito da Google Inc. (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di Wroom, compilare report e condividerli con gli altri servizi sviluppati da Google.<br />
                      Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.<br />
                      Dati Personali raccolti: Cookie e Dati di utilizzo.<br />
                      <br />
                      Luogo del trattamento: Stati Uniti – Privacy Policy – Opt Out. Soggetto aderente al Privacy Shield.
                    </p>
                    <h4 className='text-green-gradient font-weight-bold'>Come posso esprimere il consenso all'installazione di Cookie?</h4>
                    <p>In aggiunta a quanto indicato in questo documento, l'Utente può gestire le preferenze relative ai Cookie direttamente all'interno del proprio browser ed impedire – ad esempio – che terze parti possano installarne. Tramite le preferenze del browser è inoltre possibile eliminare i Cookie installati in passato, incluso il Cookie in cui venga eventualmente salvato il consenso all'installazione di Cookie da parte di questo sito. L'Utente può trovare informazioni su come gestire i Cookie con alcuni dei browser più diffusi ad esempio ai seguenti indirizzi:
                    <a rel="noopener noreferrer" target="_blank" href="https://support.google.com/chrome/answer/95647?hl=it&amp;p=cpn_cookies">Google Chrome</a>,
                    <a rel="noopener noreferrer" target="_blank" href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">Mozilla Firefox</a>,
                    <a rel="noopener noreferrer" target="_blank" href="https://support.apple.com/it-it/guide/safari/manage-cookies-and-website-data-sfri11471/">Apple Safari</a> e
                    <a rel="noopener noreferrer" target="_blank" href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies">Microsoft Internet Explorer</a>.
                    </p>
                    <p>Con riferimento a Cookie installati da terze parti, l'Utente può inoltre gestire le proprie impostazioni e revocare il consenso visitando il relativo link di opt out (qualora disponibile), utilizzando gli strumenti descritti nella privacy policy della terza parte o contattando direttamente la stessa.</p>
                    <p>Fermo restando quanto precede, l’Utente può avvalersi delle informazioni fornite da <a target="_blank" rel="noopener noreferrer" href="http://www.youronlinechoices.eu/">EDAA</a> (UE), <a target="_blank" rel="noopener noreferrer" href="https://www.networkadvertising.org/understanding-digital-advertising">Network Advertising Initiative</a> (USA) e
                    <a target="_blank" rel="noopener noreferrer" href="https://www.aboutads.info/consumers/">Digital Advertising Alliance</a> (USA), <a target="_blank" rel="noopener noreferrer" href="https://youradchoices.ca/understanding-online-advertising/">DAAC</a> (Canada),
                    <a target="_blank" rel="noopener noreferrer" href="http://www.ddai.info/optout">DDAI</a> (Giappone) o altri servizi analoghi. Con questi servizi è possibile gestire le preferenze di tracciamento della maggior parte degli strumenti pubblicitari. Il Titolare, pertanto, consiglia agli Utenti di utilizzare tali risorse in aggiunta alle informazioni fornite dal presente documento.</p>
                    <h4 className='text-green-gradient font-weight-bold'>Titolare del Trattamento dei Dati</h4>
                    <p><b>Ressolar S.r.l.</b>
                      <br />Via Carlo Serassi 21
                      <br />24124 - Bergamo
                      <br />Italia
                      <br />Capitale sociale: 100.000 Euro
                      <br />Codice fiscale e Partita Iva: 03514050164
                      <br />Camera di Commercio di Bergamo
                    </p>
                    <p><b>Indirizzo email del Titolare:</b> info@ressolar.it</p>
                    <p>
                      Dal momento che l'installazione di Cookie e di altri sistemi di tracciamento operata da terze parti tramite i servizi utilizzati all'interno di Wroom non può essere tecnicamente controllata dal Titolare, ogni riferimento specifico a Cookie e sistemi di tracciamento installati da terze parti è da considerarsi indicativo. Per ottenere informazioni complete, l’Utente è invitato a consultare la privacy policy degli eventuali servizi terzi elencati in questo documento.
                    </p>
                    <p>Vista l'oggettiva complessità di identificazione delle tecnologie basate sui Cookie l'Utente è invitato a contattare il Titolare qualora volesse ricevere qualunque approfondimento relativo all'utilizzo dei Cookie stessi tramite Wroom.</p>
                    <h6 className='text-green-gradient font-weight-bold'>Definizioni e riferimenti legali</h6>

                    <b>Dati Personali (o Dati)</b>
                    <p>Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.</p>
                    <b>Dati di Utilizzo</b>
                    <p>Sono le informazioni raccolte automaticamente attraverso Wroom (anche da applicazioni di parti terze integrate in Wroom), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente che si connette con Wroom, gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.</p>
                    <b>Utente</b>
                    <p>L'individuo che utilizza Wroom che, salvo ove diversamente specificato, coincide con l'Interessato.</p>
                    <b>Interessato</b>
                    <p>La persona fisica cui si riferiscono i Dati Personali.</p>
                    <b>Responsabile del Trattamento (o Responsabile)</b>
                    <p>La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.</p>
                    <b>Titolare del Trattamento (o Titolare)</b>
                    <p>La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di Wroom. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di Wroom.</p>
                    <b>
                      Wroom
                       (o questa Applicazione)
                    </b>
                    <p>Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.</p>
                    <b>Servizio</b>
                    <p>Il Servizio fornito da Wroom così come definito nei relativi termini (se presenti) su questo sito/applicazione.</p>
                    <b>Unione Europea (o UE)</b>
                    <p>Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello Spazio Economico Europeo.</p>
                        <b>Cookie</b>
                        <p>Piccola porzione di dati conservata all'interno del dispositivo dell'Utente.</p>

                    <hr />
                    <b>Riferimenti legali</b>
                    <p>La presente informativa privacy è redatta sulla base di molteplici ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679.</p>
                    <p>Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente Wroom.</p>

                  </div>
                </Fade>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default withScrollTop(CookiePolicy)
