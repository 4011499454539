import React from 'react'
import moment from 'moment'

class BlockInfoEvent extends React.Component {
  render(){
    const event = this.props.event
    return (
      <div className='BlockInfoEvent container pt-4 mt-4 mb-4 text-white main-text pb-4'>
        <div className='d-flex justify-content-between text-white'>
          <div>
            <div><b>{event.title}</b></div>
            <div><b>Organizzatore evento: {event.organiser}</b></div>
          </div>
          <div>
            <div><b>Inizio evento: {moment(event.eventDate).format('DD-MM-YYYY HH:mm')}</b></div>
            <div><b>Fine evento: {moment(event.endEventDate).format('DD-MM-YYYY HH:mm')}</b></div>
          </div>
        </div>
        <div className='pt-4 pb-4 mt-4 mb-4 text-white'>
          {event.description}
        </div>
      </div>
    )
  }
}

export default BlockInfoEvent
