import React from 'react'
import { connect } from 'react-redux'
import intersection from 'lodash/intersection'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import TopBar from '../../components/TopBar'
import BlockIntestation from './BlockIntestation'
import BlockConventionedEvidence from './BlockConventionedEvidence'
import BlockFilters from './BlockFilters'
import BlockActivities from './BlockActivities'
import Footer from '../../components/Footer'
import BoxOblique from '../../components/BoxOblique'
import { loadPartners, unloadPartners, getPartners  } from '../../state/partners'
import withScrollTop from '../../hocs/ScrollTop'



class Convenzionati extends React.Component {

  state = {
    selectedPartners: {}
  }

  componentDidMount(){
    this.props.loadPartners()
  }

  componentWillUnmount(){
    this.props.unloadPartners()
  }

  getPartners = (partnersProp) => {
    if(this.state.selectedPartners){
      if(isEmpty(this.state.selectedPartners)){
        return partnersProp
      }
      let selectedPartnersKeys = Object.keys(this.state.selectedPartners)
      let partners = partnersProp.filter(partner => {
          const tagsKeys = get(partner, "tagsKeys", [])
          const matchingPartnerTypes = intersection(tagsKeys, selectedPartnersKeys)
          return matchingPartnerTypes.length > 0
        })
      return partners
    }

    return partnersProp
  }

  applyFilters = (params) => {
    //this.props.unloadPartners()
    if(params.selectedPartners){
      this.setState({selectedPartners: params.selectedPartners})
    }
    this.props.loadPartners(params)
  }


  render() {
    const partners = this.getPartners(this.props.partners)
    return (
      <div className='Convenzionati'>
      <div className='bg-green-gradient pb-4'>
        <div className='position-relative pb-4'>
          <TopBar />
          <BlockIntestation />
          <BoxOblique position='bottom' />
        </div>
        {partners ?
          <BlockConventionedEvidence partners={this.props.partners} />
        :
          <div className='bg-white' style={{minHeight:400}}></div>
        }
        <div className='position-relative'>
          <BoxOblique position='top' />
          <BlockFilters selectedPartners={this.state.selectedPartners} applyFilters={this.applyFilters} />
          <BlockActivities partners={partners} />
        </div>
      </div>
      <div className='bg-green-gradient position-relative'>
        <BoxOblique position='top' fill='#00AE84' />
        <Footer />
      </div>
      </div>
    )
  }
}

export default withScrollTop(
  connect(
    state => ({
      partners: getPartners(state)
    }),
    {
      loadPartners, unloadPartners
    },
  )(Convenzionati)
)
