import React from 'react'
import { connect } from 'react-redux'
import Bounce from 'react-reveal/Bounce'
import { Link } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { transformErrorForForm } from '../Fields/utils'
import { InputField } from '../Fields'
import { sendNewsletter } from '../../state/newsletter'
import * as Yup from 'yup'
import './Footer.scss'

const NewsletterSchema = Yup.object().shape({
  emailAddress: Yup.string().email().required('E-mail è un campo obbligatorio').label('E-mail'),
})

class Footer extends React.Component {

  state = {
    message: ''
  }

  render() {
    const { sendNewsletter } = this.props
    return (
      <div className='Footer text-center pt-4 pb-4 main-text'>
        <Bounce bottom>
          <div className='text-white mt-4'>
            <b>Iscriviti alla nostra newsletter e rimani aggiornato</b>
          </div>
          <div className='pt-3 pb-3'>
            <Formik
              initialValues={{
                emailAddress: '',
              }}
              validationSchema={NewsletterSchema}
              onSubmit={(values, actions) => {
                actions.setStatus(null)
                return sendNewsletter(values).then(
                  ({ data }) => {
                    actions.setSubmitting(false)
                    this.setState({ message: 'Iscrizione newsletter avvenuta correttamente.' })
                    //actions.setStatus({ msg: 'Messaged sended.' })
                  },
                  error => {
                    actions.setSubmitting(false)
                    actions.setErrors(transformErrorForForm(error))
                  }
                )
              }}
              render={({ handleSubmit, values, isSubmitting, status, errors }) => (
                <form onSubmit={handleSubmit}>
                  <div className='container'>
                    {this.state.message !== '' &&
                      <div className='col-md-5 mx-auto alert alert-success mt-4'>
                        {this.state.message}
                      </div>
                    }
                    <div className='row pt-4'>
                      <div className='col-md-5 mx-auto'>
                        <Field
                          placeholder='E-mail'
                          type='text'
                          name='emailAddress'
                          className='form-control'
                          component={InputField}
                          addonButton='Invia'
                        />
                      </div>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
          <div className='pt-3 pb-3 text-white'>
            <a className='text-white' target="_blank" rel='noopener noreferrer' href='https://wroom.org/privacy-web.html'>Privacy</a> -
            <a className='text-white' target="_blank" rel='noopener noreferrer' href='https://wroom.org/terms-web.html'>Termini e condizioni</a> -
            <a className='text-white' target="_blank" rel='noopener noreferrer' href='https://wroom.org/cookie-web.html'>Cookie policy</a>
          </div>
          <div className='pt-4 pb-4'>
            <a target="_blank" rel='noopener noreferrer' href='https://www.facebook.com/WROOMAPP/'><img src='/images/facebook.png' alt='Facebook' /></a>
            <a target="_blank" rel='noopener noreferrer' href='https://www.instagram.com/wroom_guidailfuturo/?hl=it'><img src='/images/instagram.png' alt='Instagram' className='ml-3' /></a>
          </div>
          <div className='pt-4'>
            <img src='/images/logo-wroom.svg' alt='Logo footer' />
          </div>
          <div className='pt-4 pb-4 text-white'>
            Via Carlo Serassi, 21 - 24124 Bergamo, BG<br />
            <a className='text-white' href='mailto:info@wroom.org'>info@wroom.org</a> - +39 035 36.92.145
          </div>
          <div className='pt-4 pb-4 text-white'>
            © 2019 WROOM
          </div>
        </Bounce>
      </div>
    )
  }
}

export default connect(undefined, {
  sendNewsletter,
})(Footer)
