import React from 'react'
import { Link } from 'react-router-dom'
import Dotdotdot from 'react-dotdotdot'
import Fade from 'react-reveal/Fade'
import './BlockActivities.scss'
import _ from 'lodash'

class BlockActivities extends React.Component {
  render(){
    const partners = this.props.partners
    return (
      <div className='block-activities container pt-4 main-text' style={{minHeight: 500}}>
        <div className='row'>
          {partners && partners.map((partner) =>
          <Fade key={partner.id}>
          <div className='col-md-4'>
            <Link to={`/convenzionati/${partner.id}`}>
              <div className='activity-block d-flex'
                style={{backgroundImage: `url(${partner.mainPicture ?
                  partner.mainPicture.thumbnail.medium
                  : '/images/no_image_available.jpeg'})`}}
                >
                {_.get(partner, 'logo.thumbnail.medium',  null) &&
                <img src={partner.logo.thumbnail.medium} className='activity-block-logo'/> }

                <div className="text-white caption d-flex justify-content-between">
                  <div><b>{partner.name}</b></div>
                  <i className="fas fa-angle-right"></i>
                </div>
              </div>
            </Link>
            <div className='text-center description-text text-white pt-3'>
              <Dotdotdot clamp={3}>
                {partner.description}
              </Dotdotdot>
            </div>
          </div>
          </Fade>
          )}
        </div>
      </div>
    )
  }
}

export default BlockActivities
