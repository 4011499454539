import React from 'react'
import Bounce from 'react-reveal/Bounce';
import {
  UncontrolledCarousel,
} from 'reactstrap'
import './BlockAppCarousel.scss'

const items = [
  {
    src: '/images/previews-app-carousel/preview1.png',
    altText: 'Wroom App',
    caption: '',
  },
  {
    src: '/images/previews-app-carousel/preview2.png',
    altText: 'Wroom App',
    caption: '',
  },
  {
    src: '/images/previews-app-carousel/preview3.png',
    altText: 'Wroom App',
    caption: '',
  },
  {
    src: '/images/previews-app-carousel/preview4.png',
    altText: 'Wroom App',
    caption: '',
  },
  {
    src: '/images/previews-app-carousel/preview5.png',
    altText: 'Wroom App',
    caption: '',
  },
  {
    src: '/images/previews-app-carousel/preview6.png',
    altText: 'Wroom App',
    caption: '',
  },
]

class BlockAppCarousel extends React.Component {
  render() {
    return (
      <div className='pt-4 pb-4 text-center main-text'>
        <Bounce bottom>
          <div className='text-white m-sm-4'>
            WROOM® è il modo più facile di caricare la tua EV. <br />
            Cambia il tuo modo di viaggiare e non tornare più indietro.
          </div>
        </Bounce>
        <div className='text-white'>
          <Bounce bottom>
            <b>#Guidailfuturo</b>
          </Bounce>
        </div>
        <div className='pt-4 d-flex justify-content-center py-3'>
          <div>
            <Bounce bottom>
              <UncontrolledCarousel
                className='home-carousel'
                items={items}
              />
            </Bounce>
          </div>
        </div>
        <div>
          <img src='/images/icon-download.svg' alt='Icona download' />
          <span className='text-white ml-2'> Scarica la App dal tuo store di riferimento</span>
        </div>
        <div className='mt-2 mb-4'>
        <a href='https://itunes.apple.com/us/app/wroom/id1390504465?mt=8' rel='noopener noreferrer' target='_blank'>
          <img src='/images/button-apple-store.svg' alt='Apple store app' />
        </a>
        <a href='https://play.google.com/store/apps/details?id=it.ressolar.wroom&hl=it' rel='noopener noreferrer' target='_blank'>
        <img src='/images/button-play-store.svg' className='ml-2' alt='Play store download' />
        </a>
        </div>
      </div>
    )
  }
}

export default BlockAppCarousel
