import React from 'react'
import {
  UncontrolledCarousel,
  Modal,
} from 'reactstrap'
import './BlockEventCarousel.scss'
import _ from 'lodash'

class BlockCarouselActivity extends React.Component {

  state = {
    isOpenModal: false,
  }

  _toggle = () => this.setState({
    isOpenModal: !this.state.isOpenModal
  });

  render(){
    const { event } = this.props
    const { isOpenModal } = this.state

    const image = _.get(event, 'mainPicture.full', '/images/no_image_available.jpeg')
    // const pictures = event.pictures

    console.log({event})

    // let items = pictures.map(picture => {
    //   return {
    //     src: picture.full,
    //     altText: event.name,
    //     caption: ''
    //   }
    // })


    let items = [
      {
        src: image,
        altText: event.name,
        caption: ''
      }];

    if(items.length === 0){
      items = [{
        src: '/images/no_image_available.jpeg',
        altText: event.name,
        caption: ''
      }]
    }


    return (
      <div className='BlockEventCarousel pt-4 main-text' onClick={this._toggle}>
        <UncontrolledCarousel
          items={items}
          controls={false}
        />
        <Modal isOpen={isOpenModal} toggle={this._toggle} size={'lg'}>
          <img src={image} className='modal-image' style={{width: 800, resizeMode: 'cover'}}/>
        </Modal>
      </div>
    )
  }
}

export default BlockCarouselActivity
