import React from 'react'
import Fade from 'react-reveal/Fade';

class BlockReviews extends React.Component {
  render(){
    return (
      <Fade>
        <div className='container pt-4 pb-4 main-text'>
          <div className='pb-3'>
            <img src='/images/virgole.png' alt='Review' />
          </div>
          <div className='container'>
            <div className='mb-4'>
            WROOM® nasce dall’idea di Ressolar di aumentare l’utilizzo dei veicoli elettrici
            semplificando e digitalizzando il metodo di ricarica, secondo l’ottica delle smart-city.
            </div>
            <div>
              <b>Gianluigi Piccinini</b><br />
              Presidente di Ressolar
            </div>
          </div>
        </div>
      </Fade>
    )
  }
}

export default BlockReviews
