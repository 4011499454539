import React from 'react'
import {
  UncontrolledCarousel,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import './BlockCarouselActivity.scss'

class BlockCarouselActivity extends React.Component {
  render(){
    const partner = this.props.partner
    const pictures = partner.pictures

    let items = pictures.map(picture => {
      return {
        src: picture.full,
        altText: partner.name,
        caption: ''
      }
    })

    if(partner.mainPicture){
      items = [...items ,{
        src: partner.mainPicture.full,
        altText: partner.name,
        caption: ''
      }
    ]}

    if(items.length === 0){
      items = [{
        src: '/images/no_image_available.jpeg',
        altText: partner.name,
        caption: ''
      }]
    }

    return (
      <div className='BlockCarouselActivity main-text'>
        <div className='text-left ml-4'>
          <Link to={'/convenzionati'}><img src='/images/left-arrow.svg' alt='Left' /></Link>
        </div>
        <h5 className='text-white text-center pb-2'><b>{partner.name}</b></h5>
        <UncontrolledCarousel
          items={items}
          controls={false}
          cssModule={{padding: 100}}
        />
      </div>
    )
  }
}

export default BlockCarouselActivity
