import React from 'react'
import { connect } from 'react-redux'
import TopBar from '../../components/TopBar'
import Footer from '../../components/Footer'
import BoxOblique from '../../components/BoxOblique'
import BlockEventCarousel from './BlockEventCarousel'
import BlockInfoEvent from './BlockInfoEvent'
import BlockExhibitorsEvent from './BlockExhibitorsEvent'
import { loadEvent, unloadEvent, getEvent, getEventError } from '../../state/events'
import withScrollTop from '../../hocs/ScrollTop'
import NotFound from '../NotFound'


class DettaglioEvento extends React.Component {

  componentDidMount(){
    this.props.loadEvent(this.props.match.params.id)
  }

  componentDidUpdate(prevProps){
    if(this.props.match.params.id !== prevProps.match.params.id){
      this.props.unloadEvent()
      this.props.loadEvent(this.props.match.params.id)
    }
  }

  componentWillUnmount(){
    this.props.unloadEvent()
  }



  render() {
    if(this.props.error){
      return <NotFound />
    }
    const event = this.props.event
    console.log(event)
    return (
      <div className='DettaglioAttivita'>
        <div className='bg-green-gradient pb-4 main-text'>
          <div className='position-relative'>
            <TopBar />
            {event &&
            <div>
              <BlockEventCarousel event={event} />
              <BlockInfoEvent event={event} />
              <BlockExhibitorsEvent exhibitors={event.exhibitors} />
            </div>
            }
          </div>
        </div>
        <div className='position-relative bg-green-gradient'>
          <BoxOblique position='top' fill='#00AE84' />
          <Footer />
        </div>
      </div>
    )
  }
}

export default withScrollTop(
  connect(
    state => ({
      event: getEvent(state),
      error: getEventError(state)
    }),
    {
      loadEvent, unloadEvent
    },
  )(DettaglioEvento)
)
