import React from 'react'
import classNames from 'classnames/bind';
import Fade from 'react-reveal/Fade'
import './BlockNewChargers.scss'

class BlockNewChargers extends React.Component {

  render(){
    const news = this.props.news

    return (
      <div className='BlockNewChargers main-text'>
        <div className='text-center text-green-gradient'>
          <strong>Nuovi arrivi</strong>
        </div>
        <div className='text-center mt-2'>
          Il mondo WROOM è in continua evoluzione, STAY TUNED!!
        </div>
        <div className='container mt-4 mb-4'>
          <div className='row'>
            { news && news.map((singleNews,i) =>
              <Fade key={i}>
                <div className={classNames('col-md-4', 'text-center',{
                  'mt-4': i !== 1,
                  })}>
                  {singleNews.picture &&
                  <div className='news'>
                  <img src={singleNews.picture.full} alt={singleNews.title} className='img-fluid' />
                  <div className='mt-2'><strong>{singleNews.title}</strong></div>
                  <div>{singleNews.description}</div>
                  </div>
                  }
                </div>
              </Fade>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default BlockNewChargers
