import request from 'superagent'
import { rj } from 'redux-rocketjump'
import rjPromise from 'redux-rocketjump/plugins/promise'
import { MASTER_TOKEN, API_URL } from '../constants'

const CONTACT_REQUEST = 'CONTACT_REQUEST'

export const {
  actions: {
    load: contactUs,
  },
  saga,
} = rj(rjPromise, {
  state: false,
  type: CONTACT_REQUEST,
  api: (values) => request.post(`${API_URL}/contactforms`)
    .set('Authorization', `Bearer ${MASTER_TOKEN}`)
    .send(values)
    .then(({ body }) => body)
})()
