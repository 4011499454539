import { createStore, compose, applyMiddleware, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { middleware as thunkMiddleware } from 'redux-saga-thunk'
import { makeAppsReducers, makeAppsSaga } from 'redux-rocketjump'
import * as stations from './stations'
import * as partners from './partners'
import * as promotions from './promotions'
import * as contacts from './contacts'
import * as events from './events'
import * as news from './news'
import * as faqs from './faqs'
import * as newsletter from './newsletter'

const APPS = {
  stations,
  partners,
  promotions,
  events,
  contacts,
  news,
  faqs,
  newsletter
}

const rootReducer = combineReducers({
  ...makeAppsReducers(APPS),
})

const mainSaga = makeAppsSaga(APPS)
const preloadedState = {

}

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  preloadedState,
  composeEnhancers(
    applyMiddleware(thunkMiddleware, sagaMiddleware),
  )
)
sagaMiddleware.run(mainSaga)

export default store
