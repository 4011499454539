import React from 'react'
import { connect } from 'react-redux'
import TopBar from '../../components/TopBar'
import BlockCarouselActivity from './BlockCarouselActivity'
import BlockActivityInfo from './BlockActivityInfo'
import BlockActivityDescription from './BlockActivityDescription'
import BlockPromotions from '../Promozioni/BlockPromotions'
import Footer from '../../components/Footer'
import BoxOblique from '../../components/BoxOblique'
import { loadPartner,unloadPartner,getPartner,getPartnerError } from '../../state/partners'
import withScrollTop from '../../hocs/ScrollTop'
import NotFound from '../NotFound'


class DettaglioAttivita extends React.Component {

  componentDidMount(){
    this.props.loadPartner(this.props.match.params.id, this.props.match.params.preview)
  }

  componentDidUpdate(prevProps){
    if(this.props.match.params.id !== prevProps.match.params.id){
      this.props.unloadPartner()
      this.props.loadPartner(this.props.match.params.id)
    }
  }

  componentWillUnmount(){
    this.props.unloadPartner()
  }



  render() {
    if(this.props.error){
      return <NotFound />
    }
    const partner = this.props.partner
    return (
      <div className='DettaglioAttivita'>
        <div className='bg-green-gradient pb-4 main-text'>
          <div className='position-relative'>
            <TopBar />
            {partner &&
            <div>
            <BlockCarouselActivity partner={partner} className='pb-4' />
            <BlockActivityInfo partner={partner} className='pt-4 pb-4' />
            <BoxOblique position='bottom' />
            </div>
            }
          </div>
          {partner &&
          <>
            <BlockActivityDescription partner={partner} />
            <div className='position-relative pb-4'>
              <BoxOblique position='top'/>
              <div className='text-center text-white' style={{paddingTop: 50}}><b>Promozioni</b></div>
              <BlockPromotions promotions={partner.promotions} />
            </div>
          </>
          }
        </div>
        <div className='position-relative bg-green-gradient'>
          <BoxOblique position='top' fill='#00AE84' />
          <Footer />
        </div>
      </div>
    )
  }
}

export default withScrollTop(
  connect(
    state => ({
      partner: getPartner(state),
      error: getPartnerError(state)
    }),
    {
      loadPartner, unloadPartner
    },
  )(DettaglioAttivita)
)
