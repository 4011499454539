import get from 'lodash/get'
import isPlainObject from 'lodash/isPlainObject'
import mapValues from 'lodash/mapValues'

// Fix intial values null
// \w empty string
export const fixInitialValuesNull = (values, fields) => {
  if (!values) {
    return values
  }
  return (fields || Object.keys(values)).reduce((fixedValues, field) => ({
    ...fixedValues,
    [field]: values[field] === null ? '' : values[field],
  }), values)
}

const arrayze = a => Array.isArray(a) ? a : [a]

// Set null instead of empty string
export const setNullOnEmptyValues = (values, fields) =>
  (fields || Object.keys(values)).reduce((fixedValues, field) => ({
    ...fixedValues,
    [field]: values[field] === '' ? null : values[field],
  }), values)

export const transformErrorForForm = error => {
  if (error.status === 400 && isPlainObject(get(error, 'response.body'))) {
    // TODO: Better joins of errors...
    return mapValues(error.response.body, listOfErrors => arrayze(listOfErrors).join(','))
  }
  return {}
}
