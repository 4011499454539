import React from 'react'
import Slider from "react-slick"
import Dotdotdot from 'react-dotdotdot'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-router-dom'
import './BlockConventionedEvidence.scss'
import _ from 'lodash'

class BlockConventionedEvidence extends React.Component {
  render(){
    const partners = this.props.partners
    const fixSliderBugValue = partners.length < 2 ? partners.length : 2

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: fixSliderBugValue,
      slidesToScroll: 2,
      initialSlide: 0,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: fixSliderBugValue,
            infinite: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    console.log({partners})
    return (
      <div className='block-activities-evidence bg-white main-text'>
        <div className='container'>
          <div className='text-green-gradient pb-2 text-center'>
            <b>Convenzionati in evidenza</b>
          </div>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 offset-md-2 text-center'>
                <Fade>
                  <Slider {...settings}>
                    {partners && partners.map(partner =>
                    <Link key={partner.id} to={`/convenzionati/${partner.id}`}>
                      <div className='activity-block d-flex'
                        style={{backgroundImage: `url(${partner.mainPicture ?
                          partner.mainPicture.thumbnail.medium
                          : '/images/no_image_available.jpeg'})`}}
                        >

                        {_.get(partner, 'logo.thumbnail.medium',  null) &&
                        <img src={partner.logo.thumbnail.medium} className='activity-block-logo'/> }

                        <div className="text-white caption">
                          <div><b>{partner.name}</b></div>
                        </div>
                      </div>
                      <div className="text-green-gradient p-2">
                        <Dotdotdot clamp={3}>
                          {partner.description}
                        </Dotdotdot>
                      </div>
                    </Link>
                    )}
                  </Slider>
                </Fade>
              </div>
            </div>
        </div>
        </div>
      </div>
    )
  }
}

export default BlockConventionedEvidence
