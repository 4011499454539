import React from 'react'
import TopBar from '../../components/TopBar'
import Footer from '../../components/Footer'
import withScrollTop from '../../hocs/ScrollTop'
import Fade from 'react-reveal'

class TerminiCondizioni extends React.Component {
  render(){
    return (
      <div className='main-text'>
        <div className='bg-green-gradient pb-4'>
          <TopBar />
          <div className='bg-white pl-4 pt-4'>
            <div className='container main-text'>
              <div className='row'>
                <Fade>
                  <div className='col-md-12'>
                    <h3 className='text-green-gradient'>Titolare di www.wroom.org e dei relativi Servizi</h3>
                    <p>
                    Ressolar S.r.l.
                    <br />Via Carlo Serassi 21
                    <br />24124 - Bergamo
                    <br />Italia
                    <br />Capitale sociale: 100.000 Euro
                    <br />Codice fiscale e Partita Iva: 03514050164
                    <br />Camera di Commercio di Bergamo
                    </p>
                    <p>
                      <strong>Indirizzo email del Titolare</strong>: info@ressolar.it
                    </p>
                    <h3 className='text-green-gradient'>Informazioni su www.wroom.org</h3>
                    <p>
                      www.wroom.org è un sito web che presenta e promuove l'applicazione mobile Wroom
                    </p>
                    <h3 className='text-green-gradient'>Introduzione</h3>
                    <h5 className='text-green-gradient'>Il presente documento</h5>
                    <p>
                      Il presente documento costituisce un accordo legale fra te, in qualità di Utente, e la società che gestisce www.wroom.org e disciplina il tuo utilizzo del sito web ed, in ogni caso, l’uso dei servizi forniti. “Accordo legale” significa che i termini di tale accordo, una volta accettati dall'Utente, sono vincolanti per quest'ultimo.
                      Per semplicità, “Utente”, “tu”, “tuo” e termini analoghi, sia al singolare che al plurale, si riferiscono a te, l’Utente. “Noi”, “nostro” e termini simili si riferiscono alla società che possiede e gestisce www.wroom.org. “www.wroom.org” si riferisce al presente sito e/o applicazione. “Contratto” si riferisce al presente documento, come di volta in volta emendato. Il Contratto è concluso in lingua italiana. Altre definizioni possono essere rinvenute nella sezione “Definizioni” in calce a questo Contratto.
                    </p>
                    <h5 className='text-green-gradient'>Accettazione del Contratto</h5>
                    <p>
                      Per poter utilizzare www.wroom.org, devi leggere attentamente ed accettare il Contratto cliccando sullo specifico pulsante per l’accettazione. Se non accetti il Contratto non potrai utilizzare il Servizio.
                    </p>
                    <h3 className='text-green-gradient'>Contenuto di www.wroom.org e utilizzo vietato</h3>
                    <h5 className='text-green-gradient'>Contenuti disponibili su www.wroom.org</h5>
                    <p>
                      I contenuti disponibili su www.wroom.org sono protetti dalla legge sul diritto d’autore e dalle altre leggi e trattati internazionali posti a tutela dei diritti di proprietà intellettuale e, ove non diversamente specificato, il loro utilizzo è consentito agli Utenti esclusivamente nei limiti specificati nella presente clausola.
                      Il Titolare concede all’Utente, per tutta la durata del Contratto, una licenza personale, non trasferibile e non esclusiva, ad esclusivi fini personali e mai commerciali e limitatamente al dispositivo in uso all'Utente per l'utilizzo di tali contenuti.
                      Pertanto, è fatto espresso divieto all’Utente di copiare e/o scaricare e/o condividere (se non nei limiti di seguito illustrati), modificare, pubblicare, trasmettere, vendere, sublicenziare, elaborare, trasferire/cedere a terzi o creare lavori derivati in qualsiasi modo dai contenuti, anche di terzi, disponibili su www.wroom.org, né consentire che terzi lo facciano tramite l’Utente o il suo dispositivo, anche a sua insaputa.
                      Ove espressamente indicato su www.wroom.org, l'Utente, per mero uso personale, può essere autorizzato a scaricare e/o copiare e/o condividere alcuni contenuti resi disponibili su www.wroom.org, a condizione che riporti fedelmente tutte le indicazioni di copyright e le altre indicazioni fornite dal Titolare.
                    </p>
                    <h5 className='text-green-gradient'>Contenuti forniti da terzi</h5>
                    <p>
                      Il Titolare non effettua alcuna moderazione preventiva sui contenuti o i link forniti da terzi mostrati su www.wroom.org. Il Titolare non è responsabile di tali contenuti e della loro accessibilità.
                    </p>
                    <h5 className='text-green-gradient'>Contenuti forniti dall'Utente</h5>
                    <p>
                      Gli Utenti sono responsabili per i contenuti propri e di terzi che condividono su www.wroom.org, mediante il loro caricamento, l'inserimento di contenuti o con qualsiasi altra modalità. Gli Utenti manlevano il Titolare da ogni responsabilità in relazione alla diffusione illecita di contenuti di terzi o all'utilizzo di www.wroom.org, con modalità contrarie alla legge.
                    </p>
                    <p>
                      Il Titolare non svolge alcun tipo di moderazione dei contenuti pubblicati dall’Utente o da terzi, ma si impegna ad intervenire a fronte di segnalazioni degli Utenti o di ordini impartiti da pubbliche autorità in relazione a contenuti ritenuti offensivi o illeciti.
                      In particolare, il Titolare potrebbe sospendere o interrompere la visualizzazione dei contenuti nel caso in cui:
                    </p>
                    <ul>
                      <li>pervengano lamentele da parte di altri Utenti;</li>
                      <li>riceva una segnalazione di violazione di diritti di proprietà intellettuale;</li>
                      <li>ritenga di dover fare ciò in previsione di, o in esito a, azioni legali;</li>
                      <li>tale azione sia richiesta da pubblica autorità; oppure</li>
                      <li>ritenga che tale contenuto, rimanendo accessibile attraverso www.wroom.org potrebbe mettere a rischio gli Utenti, i terzi, la disponibilità del Servizio e/o lo stesso Titolare.</li>
                    </ul>
                    <h5 className='text-green-gradient'>Diritti sui contenuti forniti dagli Utenti</h5>
                    <p>
                      Gli unici diritti concessi al Titolare in relazione ai contenuti forniti dagli Utenti sono quelli necessari al funzionamento ed al mantenimento di www.wroom.org.
                    </p>
                    <p>
                      Salvo che sia diversamente stabilito, con l’invio, la pubblicazione o visualizzazione di contenuti su o mediante www.wroom.org, l’Utente concede al Titolare ed agli altri Utenti una licenza senza limiti territoriali, non esclusiva, gratuita e con diritto di sublicenza, per utilizzo, copia, riproduzione, elaborazione, adattamento, modifica, pubblicazione, trasmissione, visualizzazione e distribuzione di tali contenuti con qualsiasi supporto o metodo di distribuzione attualmente disponibile o sviluppato in seguito.
                    </p>
                    <h5 className='text-green-gradient'>Servizi forniti da terze parti</h5>
                    <p>
                      Gli Utenti possono utilizzare servizi o contenuti inclusi in www.wroom.org forniti da terze parti, ma devono prima aver preso visione dei termini e condizioni di tali terze parti ed averle accettate. In nessuna circostanza il Titolare potrà essere ritenuto responsabile in relazione al corretto funzionamento o alla disponibilità, o entrambi, di servizi forniti da terze parti.
                    </p>
                    <h5 className='text-green-gradient'>Utilizzo non consentito</h5>
                    <p>Il Servizio dovrà essere utilizzato secondo quanto stabilito nei Termini. <br />
                      Gli Utenti non possono:
                    </p>
                    <ul>
                      <li>effettuare reverse engineering, decompilare, disassemblare, modificare o creare lavori derivati basati su www.wroom.org o su qualunque porzione di essa;</li>
                      <li>aggirare i sistemi informatici usati da www.wroom.org o dai suoi licenziatari per proteggere il contenuto accessibile tramite di essa;</li>
                      <li>copiare, conservare, modificare, cambiare, preparare lavori derivati o alterare in qualunque modo qualunque dei contenuti forniti da www.wroom.org;</li>
                      <li>utilizzare qualunque robot, spider, applicazione di ricerca e/o di reperimento di siti, ovvero qualunque altro dispositivo, processo o mezzo automatico per accedere, recuperare, effettuare scraping o indicizzare qualunque porzione di www.wroom.org o dei suoi contenuti;</li>
                      <li>affittare, licenziare o sublicenziare www.wroom.org;</li>
                      <li>diffamare, offendere, molestare, mettere in atto pratiche minatorie, minacciare o in altro modo violare i diritti di altri;</li>
                      <li>diffondere o pubblicare contenuti illegali, osceni, illegittimi, diffamatori o inappropriati;</li>
                      <li>appropriarsi illecitamente dell’account in uso presso altro Utente;</li>
                      <li>registrarsi o utilizzare il Servizio al fine di approcciare gli Utenti per promuovere, vendere o pubblicizzare in qualsiasi modo prodotti o servizi di qualsivoglia tipo attraverso www.wroom.org;</li>
                      <li>utilizzare www.wroom.org in qualunque altra modalità impropria tale da violare i Termini.</li>
                    </ul>
                    <h3 className='text-green-gradient'>Risarcimento e limitazione di responsabilità</h3>
                    <h5 className='text-green-gradient'>Manleva</h5>
                    <p>
                      L'Utente si impegna a mantenere indenne il Titolare (nonché le eventuali società dallo stesso controllate o affiliate, i suoi rappresentanti, amministratori, agenti, licenziatari, partner e dipendenti), da qualsiasi obbligo o responsabilità, incluse le eventuali spese legali sostenute per difendersi in giudizio, che dovessero sorgere a fronte di danni provocati ad altri Utenti o a terzi, in relazione ai contenuti caricati online, alla violazione della legge o dei termini delle presenti condizioni di servizio.
                    </p>
                    <h3 className='text-green-gradient'>Limitazioni di responsabilità</h3>
                    <p>
                      Www.wroom.org e tutte le funzionalità accessibili attraverso www.wroom.org vengono messi a disposizione degli Utenti, ai termini e alle condizioni di cui al Contratto, senza alcuna garanzia, esplicita o implicita, che non sia obbligatoria per legge. In particolare, non viene fornita alcuna garanzia d’idoneità dei servizi offerti per gli scopi particolari che si prefigge l’Utente.
                      L’uso di www.wroom.org e delle funzionalità accessibili attraverso www.wroom.org è effettuato dagli Utenti a proprio rischio e sotto la propria responsabilità.
                      In particolare il Titolare, nei limiti del diritto applicabile, risponde per i danni di natura contrattuale ed extracontrattuale nei confronti di Utenti e terzi esclusivamente a titolo di dolo o colpa grave quando questi costituiscono conseguenza immediata e diretta dell’attività di www.wroom.org. Pertanto, il Titolare non sarà responsabile per:
                    </p>
                    <ul>
                      <li>eventuali perdite che non siano conseguenza diretta della violazione del Contratto da parte del Titolare;</li>
                      <li>ogni perdita di opportunità commerciale e qualsiasi altra perdita, anche indiretta, eventualmente subita dall'Utente (quali, a titolo esemplificativo e non esaustivo, perdite commerciali, perdita di ricavi, introiti, profitti o risparmi presunti, perdita di contratti o di relazioni commerciali, perdita della reputazione o del valore di avviamento etc.);</li>
                      <li>danni o perdite derivanti da interruzioni o malfunzionamenti di www.wroom.org dovuti ad eventi di forza maggiore o, comunque, ad eventi imprevisti ed imprevedibili e, comunque, indipendenti dalla volontà ed estranei alla sfera di controllo del Titolare quali, a titolo esemplificativo e non esaustivo, guasti o interruzioni alle linee telefoniche o elettriche, alla rete internet e/o comunque ad altri strumenti di trasmissione, indisponibilità di siti web, scioperi, eventi naturali, virus ed attacchi informatici, interruzioni nell’erogazione di prodotti, servizi o applicazioni di terze parti; ed</li>
                      <li>errato o inidoneo utilizzo di www.wroom.org da parte degli Utenti o di terzi.</li>
                    </ul>
                    <h3 className='text-green-gradient'>Disposizioni comuni</h3>
                    <h5 className='text-green-gradient'>Interruzione del Servizio</h5>
                    <p>
                      Per garantire agli Utenti la migliore fruizione possibile del Servizio, il Titolare si riserva il diritto di interrompere il Servizio per necessità di manutenzione o aggiornamento del sistema, informando gli Utenti attraverso aggiornamenti costanti in merito su www.wroom.org.
                    </p>
                    <h5 className='text-green-gradient'>Rivendita del Servizio</h5>
                    <p>
                      Gli Utenti non sono autorizzati a riprodurre, duplicare, copiare, vendere, rivendere o sfruttare qualunque porzione di www.wroom.org e dei suoi Servizi senza il previo permesso scritto da parte del Titolare, garantito direttamente o tramite uno specifico programma di rivendita.
                    </p>
                    <h5 className='text-green-gradient'>Privacy policy</h5>
                    <p>
                      Per informazioni sull'utilizzo dei dati personali, gli Utenti devono fare riferimento alla privacy policy di www.wroom.org, che è considerata parte di questi Termini.
                    </p>
                    <h5 className='text-green-gradient'>Diritti di proprietà intellettuale</h5>
                    <p>
                      Tutti i marchi dell'Applicazione, figurativi o nominativi, e tutti gli altri segni, nomi commerciali, marchi di servizio, marchi denominativi, denominazioni commerciali, illustrazioni, immagini, loghi che appaiono riguardanti www.wroom.org sono e rimangono di proprietà esclusiva del Titolare o dei suoi licenziatari e sono protetti dalle leggi vigenti sui marchi e dai relativi trattati internazionali.
                    </p>
                    <p>
                      Tutti i marchi e tutti gli altri segni, nomi commerciali, marchi di servizio, marchi denominativi, denominazioni commerciali, illustrazioni, immagini, loghi riguardanti terze parti ed i contenuti pubblicati da tali terzi su www.wroom.org sono e rimangono di proprietà esclusiva o nella disponibilità di detti terzi e dei loro licenziatari e sono protetti dalle leggi vigenti sui marchi e dai relativi trattati internazionali. Il Titolare non possiede la titolarità di tali privative intellettuali e può servirsene solo entro i limiti ed in conformità ai contratti conclusi con tali terze parti e per gli scopi ivi delineati.
                    </p>
                    <h5 className='text-green-gradient'>Modifiche ai presenti Termini</h5>
                    <p>
                      Il Titolare si riserva il diritto di apportare modifiche ai Termini in qualunque momento, dandone avviso all'Utente mediante la sua pubblicazione all'interno di www.wroom.org.
                      L'Utente che continui ad utilizzare www.wroom.org successivamente alla pubblicazione delle modifiche, accetta senza riserva i nuovi Termini.
                    </p>
                    <h5 className='text-green-gradient'>Cessione del contratto</h5>
                    <p>
                      Il Titolare si riserva il diritto di trasferire, cedere, disporre per novazione o subappaltare tutti o alcuni dei diritti o obblighi derivanti dai Termini, purché i diritti dell’Utente qui previsti non siano pregiudicati.
                      L’Utente non potrà cedere o trasferire in alcun modo i propri diritti o obblighi ai sensi dei Termini senza l’autorizzazione scritta del Titolare.
                    </p>
                    <h5 className='text-green-gradient'>Comunicazioni</h5>
                    <p>Tutte le comunicazioni relative a www.wroom.org devono essere inviate utilizzando le informazioni di contatto indicate nel Contratto.</p>
                    <h5 className='text-green-gradient'>Inefficacia e nullità parziale</h5>
                    <p>
                      Qualora una qualsiasi clausola dei Termini dovesse risultare nulla, non valida o inefficace, la suddetta clausola sarà eliminata mentre le restanti clausole non saranno da ciò condizionate e rimarranno pienamente efficaci.
                    </p>
                    <h5 className='text-green-gradient'>Legge applicabile e Foro competente</h5>
                    <p>
                      I presenti Termini e tutte le controversie in merito ad esecuzione, interpretazione e validità del presente contratto sono soggette alla legge, alla giurisdizione dello Stato e alla competenza esclusiva del tribunale del luogo in cui ha sede legale il Titolare.
                      Fa eccezione il foro esclusivo del consumatore, qualora la legge lo preveda.
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default withScrollTop(TerminiCondizioni)
