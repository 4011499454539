import React from 'react'
import Fade from 'react-reveal/Fade'
import PromotionModal from '../PromotionModal'
import './BlockPromotions.scss'

class BlockPromotions extends React.Component {

  state = {
    isOpenPromotionModal : false,
    promotion: null
  }

  handlePromotion = (promotion) => {
    this.setState({ isOpenPromotionModal: true, promotion: promotion})
  }

  togglePromotionModal = () => {
    this.setState({ isOpenPromotionModal: !this.state.isOpenPromotionModal})
  }

  render(){
    const promotions = this.props.promotions
    return (
      <div className='block-promotions container pt-4 main-text' style={{minHeight: 500}}>
        <div className='row'>
          {promotions && promotions.map((promotion) =>
          <Fade key={promotion._id}>
            <div className='col-md-4'>
              <div
                className='activity-block d-flex pointer'
                onClick={() => this.handlePromotion(promotion)}
                style={{backgroundImage: `url(${promotion.mainPicture ?
                  promotion.mainPicture.thumbnail.medium
                  : '/images/no_image_available.jpeg'})`}}
                >
                <div className="text-white caption text-center">
                  <div><b>{promotion.name}</b></div>
                  <div className='text-center text-white pt-2'>
                    {promotion.discountDescription}
                  </div>
                </div>

              </div>
            </div>
          </Fade>
          )}
        </div>

        {this.state.isOpenPromotionModal &&
        <PromotionModal
          toggle={this.togglePromotionModal}
          isOpen={this.state.isOpenPromotionModal}
          promotion={this.state.promotion}
        />}
      </div>
    )
  }
}

export default BlockPromotions
