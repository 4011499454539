import React from 'react'
import { connect } from 'react-redux'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import classNames from 'classnames/bind';
import TopBar from '../../components/TopBar'
import MapWroom from '../../components/MapWroom'
import SearchStations from '../../components/SearchStations'
import Footer from '../../components/Footer'
import withScrollTop from '../../hocs/ScrollTop'
import debounce from 'lodash/debounce'
import intersection from 'lodash/intersection'
import get from 'lodash/get'
import omit from 'lodash/omit'
import has from 'lodash/has'
import {
  // Geo stations
  loadGeoStations,
  getGeoStations,
  unloadGeoStations,
  // Search stations
  searchStations,
  unloadSearchStations,
  getSearchStations,
} from '../../state/stations'
import {
  loadGeoPartners,
  unloadGeoPartners,
  getGeoPartners,
  loadPartners,
  unloadPartners,
  getPartners
} from '../../state/partners'
import { ICONS_STATIONS, TAG_ICONS, TAG_ICONS_FILTERS } from '../../constants'
import './Mappa.scss'

const MAIN_MAP_CENTER = { lat: 45.703858, lng: 9.6919158 }

const empty = []

class Mappa extends React.Component {

  state = {
    centerMap : null,
    mapType : 'zones',
    lastGeoParams : [],
    lastSearch : '',
    //# TODO: USE FOR POPOVER
    partnersPopoverOpen: false,
    selectedPartners: {},
    popoverOpen: false,
  }

  toggleSelectedPartner = (partnerType) => () => {
    let selectedPartners
    if(this.state.selectedPartners[partnerType] !== undefined){
      selectedPartners = omit(this.state.selectedPartners, [partnerType])
    } else {
      selectedPartners = {
        ...this.state.selectedPartners,
        [partnerType]: true,
      }
    }
    this.setState({selectedPartners})
  }

  togglePopOver = () => {
    this.setState({popoverOpen: false})
  }

  componentWillUnmount() {
    this.props.unloadGeoStations()
    this.props.unloadGeoPartners()
    this.props.unloadPartners()
    this.props.unloadSearchStations()
  }

  getMarkers = (stations, type='station') => {
    if (stations === null) {
      return []
    }

    if(type === 'partner'){

      const tagIcons = Object.keys(TAG_ICONS)

      let selectedPartners = Object.keys(this.state.selectedPartners)
      let partners = selectedPartners.length === 0
        ? stations
        : stations.filter(station => {
          const tagsKeys = get(station, "partner.tagsKeys", [])
          const matchingPartnerTypes = intersection(tagsKeys, selectedPartners)
          return matchingPartnerTypes.length > 0
        })

      return partners.map(station => {
        const tagsKeys = get(station, "partner.tagsKeys", [])

        const availableIcons = intersection(tagsKeys, tagIcons)
        const icon = availableIcons.length > 0 ? TAG_ICONS[availableIcons[0]] : TAG_ICONS['culture']
        return  {
          ...station,
          description: station.location.address,
          title: station.name,
          id: station.location._id,
          icon: icon,
          position: {lat: station.location.coordinates[1], lng: station.location.coordinates[0]}
        }
      })
    }

    return stations.map(station => {

      const icon = ICONS_STATIONS[station.state]

      return {
        ...station,
        description: station.location.address,
        title: station.name,
        id: station.location._id,
        icon: icon,
        position: {lat: station.location.coordinates[1], lng: station.location.coordinates[0]}
      }
    })
  }

  setMapType = (type) => {
    this.setState({ mapType : type }, () => {
      this.loadMarkers(...this.state.lastGeoParams)
      this.handleOnSearchChange(this.state.lastSearch)
    })
  }

  loadMarkers = (...args) => {
    this.setState({ lastGeoParams: args })
    if (this.state.mapType === 'zones') {
      return this.loadGeoStationsMarkers(...args)
    }
    else{
      return this.loadPartnerStationsMarkers(...args)
    }
  }

  loadGeoStationsMarkers = debounce(this.props.loadGeoStations, 300)
  loadPartnerStationsMarkers = debounce(this.props.loadGeoPartners, 300)

  searchForStations = debounce(this.props.searchStations, 300)
  searchForPartners = debounce(this.props.loadPartners, 300)

  handleOnSearchChange = (search) => {
    if(search !== ''){
      this.setState({ lastSearch: search })
      if(this.state.mapType === 'zones'){
        this.searchForStations({ q: search })
      }
      else{
        this.props.loadPartners({ limit : 7, 'name.$contains': search})
      }

    }
  }

  handleSelectCenter = (item) => {
    this.setState({
      centerMap : { lat: item.location.coordinates[1], lng: item.location.coordinates[0]}
    })
  }

  getSearchMarkers = (type) => {
    if(type === 'zones'){
      return this.props.searchedStations || empty
    }
    else{
      return this.props.searchedPartners || empty
    }
  }

  render() {

    const markers = this.state.mapType === 'zones' ?
                    this.getMarkers(this.props.geoStations, 'station') :
                    this.getMarkers(this.props.geoPartners, 'partner')
    return (
      <div className='Mappa'>
        <div className='bg-green-gradient position-relative pb-4'>
          <TopBar />
          <div className="w-100 position-relative" style={{ height:500}}>
            <SearchStations
              onSearchChange={this.handleOnSearchChange}
              onSelectCenter={this.handleSelectCenter}
              stations={this.getSearchMarkers(this.state.mapType)}
              mapType={this.state.mapType}
            />
            <div className='filters position-absolute text-center pt-2 pb-2 d-flex flex-row row'>


              <div className='d-flex justify-content-end text-right col-3 col-md-6 pl-md-0 pl-2'>
                <div className='align-items-center d-flex  flex-column ml-4'>
                  <div
                    onClick={() => this.setMapType('zones')}
                    className={classNames('filter', {
                      'active': this.state.mapType === 'zones',
                    })}>
                    <img src='/images/map-pointer.svg' width='25' alt='Map pointer' />
                  </div>
                  <small><strong>Smart zone</strong></small>
                </div>
              </div>

              <div className='d-flex text-right flex-row col-9 col-md-6 '>
                <div className='align-items-center d-flex flex-column ml-4'>
                  <div
                    onClick={() => this.setMapType('convenzionati')}
                    className={classNames('filter', {
                      'active': this.state.mapType === 'convenzionati',
                    })}>
                    {this.state.mapType === 'convenzionati' ?
                    <img src='/images/map-convenzionati-pointer-white.png' width='25' alt='Map pointer' />
                    :
                    <img src='/images/map-convenzionati-pointer.svg' width='25' alt='Map pointer' />
                    }
                  </div>
                  <small><strong>Convenzionati</strong></small>
                </div>

                  <>
                  <div>
                  <button
                    className={classNames('invia-button','btn-sm','ml-4','mt-3','btn-secondary',{
                    'd-none': this.state.mapType === 'zones'
                    })}
                    onClick={() => this.setState({ popoverOpen: true })}
                    id="Popover1"
                    >
                    {/* {!this.state.popoverOpen ? 'Filtri' : 'Chiudi'} */}
                    Filtri
                  </button>

                  <Popover className={classNames({
                  'd-none': this.state.mapType === 'zones'
                })} placement="bottom" isOpen={this.state.popoverOpen} target="Popover1" toggle={this.togglePopOver}>
                    <PopoverHeader>Filtri convenzionati</PopoverHeader>
                    <PopoverBody>
                      {TAG_ICONS_FILTERS.map(tag =>
                        <div
                          key={tag['value']}
                          className={classNames('pointer',{
                          'active-filter': has(this.state.selectedPartners,tag['value'])
                        })}
                          onClick={this.toggleSelectedPartner(tag['value'])}>
                          <img src={tag['icon']} alt='map-pointer' className='mr-1' />
                          {tag['label']}
                        </div>
                      )}
                    </PopoverBody>
                    <div className='text-left pointer close-pop' onClick={this.togglePopOver}>
                      <img src='/images/close-modal.png' width={20} alt='Close Modal' />
                    </div>
                  </Popover>

                </div>
                <div className='d-flex mt-3 flex-wrap filters-label'>
                  {TAG_ICONS_FILTERS.map(tag =>
                    has(this.state.selectedPartners,tag['value']) &&
                    <span key={tag['value']} className={classNames('ml-2',{'d-none': this.state.mapType === 'zones'})}>{tag['label']}</span>
                  )}
                </div>
                </>

              </div>

            </div>
            <MapWroom
              loadMarkers={this.loadMarkers}
              markers={markers}
              options={{
                draggable: true,
                zoomControl: true,
              }}
              center={ this.state.centerMap !== null ? this.state.centerMap : MAIN_MAP_CENTER}
            />
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default withScrollTop(connect(state => ({
  searchedStations: getSearchStations(state),
  geoStations: getGeoStations(state),
  geoPartners: getGeoPartners(state),
  searchedPartners: getPartners(state)
}), {
  loadGeoStations,
  unloadGeoStations,
  searchStations,
  unloadSearchStations,
  loadGeoPartners,
  unloadGeoPartners,
  loadPartners,
  unloadPartners,
})(Mappa))
