import React from 'react'
import Fade from 'react-reveal/Fade'

class BlockIntestation extends React.Component {
  render(){
    return (
      <div className='container text-center pb-4 main-text text-white'>
        <Fade left>
          <div className='pt-4 pb-4'>
            <b>Scopri tutte le promozioni attive in WROOM.</b>
          </div>
        </Fade>
        <Fade left>
          <div className='pt-3 pb-3'>

          </div>
        </Fade>
      </div>
    )
  }
}

export default BlockIntestation
