import React from 'react'
import { Input, Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import classNames from 'classnames/bind';
import { TAG_ICONS_FILTERS } from '../../../constants'
import has from 'lodash/has'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import './BlockFilters.scss'

class BlockFilters extends React.Component {

  state = {
    search: '',
    popoverOpen: false,
  }

  applyFilters = (filters) => {
    this.props.applyFilters({
      'name.$contains': filters.search,
      'selectedPartners' : filters.selectedPartners,
    })
  }

  applyFiltersDebounced = debounce(this.applyFilters, 250)

  handleOnSearchChange = (e) => {
    this.setState({search: e.target.value}, () => {
      this.applyFiltersDebounced(this.state)
    })
  }

  toggleSelectedPartner = (partnerType) => () => {
    let selectedPartners
    if(this.props.selectedPartners[partnerType] !== undefined){
      selectedPartners = omit(this.props.selectedPartners, [partnerType])
    } else {
      selectedPartners = {
        ...this.props.selectedPartners,
        [partnerType]: true,
      }
    }
    this.applyFilters({ selectedPartners, search: this.state.search})
  }

  togglePopOver = () => {
    this.setState({popoverOpen: false})
  }

  render(){
    return (
      <div className='BlockFilters pt-4 pb-4'>
        <div className='text-white text-center main-text pt-4 pb-4'>
          <b>
            Stai cercando un’attività in particolare? <br/>
          </b>
        </div>
        <div className='container pt-4'>
          <div className='row'>
            <div className='col-md-4 col-sm-12'>
              <Input type='text' value={this.state.search} className='search' onChange={this.handleOnSearchChange}
                     style={{borderRadius: '0.75em'}}/>
            </div>
            <div className='col-md-4 mt-2 mt-md-0 text-right col-sm-12 offset-md-4 text-white pl-4'>
              <>
                <div>
                  <button
                    className='btn btn-filters btn-md'
                    onClick={() => this.setState({popoverOpen: true})}
                    id="Popover1"
                  >
                    Filtri
                  </button>
                  {' '}
                  {(this.state.search !== '' || !isEmpty(this.props.selectedPartners)) &&
                  <button
                    className='btn btn-filters btn-md'
                    onClick={() => {
                      this.applyFilters({selectedPartners: {}})
                      this.setState({search: ''})
                    }}
                  >
                    Reset
                  </button>
                  }

                  <Popover placement="bottom" isOpen={this.state.popoverOpen} target="Popover1"
                           toggle={this.togglePopOver}>
                    <PopoverHeader>Filtri convenzionati</PopoverHeader>
                    <PopoverBody>
                      {TAG_ICONS_FILTERS.map(tag =>
                        <div
                          key={tag['value']}
                          className={classNames('pointer', {
                            'active-filter': has(this.props.selectedPartners, tag['value'])
                          })}
                          onClick={this.toggleSelectedPartner(tag['value'])}>
                          <img src={tag['icon']} alt='map-pointer' className='mr-1'/>
                          {tag['label']}
                        </div>
                      )}
                    </PopoverBody>
                    <div className='text-left pointer close-pop' onClick={this.togglePopOver}>
                      <img src='/images/close-modal.png' width={20} alt='Close Modal'/>
                    </div>
                  </Popover>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BlockFilters
