import React from 'react'

export default function BoxOblique({ height, fill , position }) {
  return (
    <svg
      style={{ position: 'absolute' }}
      className={ position === 'bottom' ? "bottom-0" : "top-0"}
      width='100%' height={height} viewBox='0 0 100 100' preserveAspectRatio="none">
       <polygon
         style={{ fill: fill }}
         points={ position === 'bottom' ? "0,100 0,60 100,0 100,100" : "0,0 0,60 100,0 0,0"}

       />
    </svg>
  )
}

BoxOblique.defaultProps = {
  height: 60,
  fill: 'white',
  position: 'bottom'
}
