import React from 'react'
import Slide from 'react-reveal/Slide'

class BlockGiftCharger extends React.Component {
  render(){
    return (
      <div className='container text-center pt-4 pb-4'>
        <h5 className='main-text text-white pt-4 pb-4'>Non aspettare in macchina mentre <br /> ricarichi la tua EV</h5>
        <div className='row'>
          <div className='col-md-4 pt-2'>
            <Slide left><img src='/images/partners-screen/partner-screen-1.png' alt='Gift Charger' /></Slide>
          </div>
          <div className='col-md-4 pt-2'>
            <Slide top><img src='/images/partners-screen/partner-screen-2.png' alt='Gift Charger' /></Slide>
          </div>
          <div className='col-md-4 pt-2'>
            <Slide right><img src='/images/partners-screen/partner-screen-3.png' alt='Gift Charger' /></Slide>
          </div>
        </div>
        <div className='main-text text-white pt-4 pb-4'>
          Scopri tutti i convenzionati WROOM ed usufruisci di tutte le promozioni e di tutti i vantaggi di essere un Ev driver<br /> utilizzatore dell’applicazione WROOM.
        </div>
      </div>
    )
  }
}

export default BlockGiftCharger
