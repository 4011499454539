import request from 'superagent'
import { rj } from 'redux-rocketjump'
import combineRjs from 'redux-rocketjump/plugins/combine'
import posArgs from 'redux-rocketjump/plugins/positionalArgs'
import { MASTER_TOKEN, API_URL } from '../constants'

const config = {}

const GET_EVENTS = 'GET_EVENTS'
config.list = rj({
  type: GET_EVENTS,
  api: (params) =>
    request.get(`${API_URL}/partnerEvents`)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .query(params)
      .then(({ body }) => body)
})

const GET_EVENT = 'GET_EVENT'
config.detail = rj(posArgs(), {
  type: GET_EVENT,
  api: (id) =>
    request.get(`${API_URL}/partnerEvents/${id}`)
      .set('Authorization', `Bearer ${MASTER_TOKEN}`)
      .then(({ body }) => body)
})



export const {
  connect: {
    list: {
      actions: {
        load: loadEvents,
        unload: unloadEvents,
      },
      selectors: {
        getData: getEvents,
      }
    },
    detail: {
      actions: {
        load: loadEvent,
        unload: unloadEvent,
      },
      selectors: {
        getData: getEvent,
        getError: getEventError,
      }
    }
  },
  saga,
  reducer,
} = combineRjs(config, {
  state: 'events',
})
