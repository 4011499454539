import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"
import uniqBy from 'lodash/uniqBy'
import { MAPS_API_KEY, DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM }  from '../../constants'
import './MapWroom.scss'

class CustomInfoWindow extends React.Component {

  onDomReady() {
    const a = this.getContent()
    try {
      const b = a.parentNode.parentNode.parentNode
      b.firstChild.lastChild.classList.add('info-window-content')
      b.firstChild.childNodes[b.firstChild.childNodes.length - 2].classList.add('info-window-content-arrows')
    } catch (err) {
      console.error("Can't set color to gmaps infowindow...", err)
    }
  }

  render(){
    const { ...passProps } = this.props
    return <InfoWindow onDomReady={this.onDomReady} {...passProps}/>
  }
}

const MapComponent = withScriptjs(withGoogleMap(class extends React.Component {

  state = {
    markerOpen : false
  }

  map = null

  openMarker = markerOpen => e => this.setState({markerOpen})
  closeMarker = e => this.setState({markerOpen: null})



  render(){
    const { markers, center, options, loadMarkers } = this.props

    const markersUnique = uniqBy(markers,'_id')

    const { markerOpen } = this.state
    return (
      <GoogleMap
        ref={r => this.map = r}
        defaultZoom={DEFAULT_MAP_ZOOM}
        options={options}
        center={center}
        onBoundsChanged={() => {
          const center = this.map.getCenter()
          const bounds = this.map.getBounds()
          const deltaLat = Math.abs(bounds.getNorthEast().lat() - bounds.getSouthWest().lat())
          const deltaLong = Math.abs(bounds.getNorthEast().lng() - bounds.getSouthWest().lng())
          if (typeof loadMarkers === 'function') {
            loadMarkers({
              lat: center.lat(),
              long: center.lng(),
              dlat: deltaLat,
              dlong: deltaLong,
            })
          }
        }}

      >
        {markersUnique && markersUnique.length > 0 && <React.Fragment>
          {markersUnique.map(marker => (
            marker._id !== undefined &&
            <Marker key={marker._id}
              icon={marker.icon}
              position={marker.position} onClick={this.openMarker(marker._id)}>
              { markerOpen === marker._id && <CustomInfoWindow onCloseClick={this.closeMarker} defaultPosition={marker.position}>
                <div className='bg-white'>
                  <h5>{marker.title ? marker.title : marker.partner.name}</h5>
                  <p>
                    {marker.description}
                  </p>
                </div>
              </CustomInfoWindow>}
            </Marker>)
          )}
        </React.Fragment>}

      </GoogleMap>
    )
  }
}))


class MapWroom extends React.PureComponent {

  render(){
    const { center, markers, options, ...props } = this.props
    return (
      <div className="MapWroom bg-dark">
        <MapComponent
          center={center}
          markers={markers}
          options={{
            streetViewControl: false,
            zoomControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            draggable: false,
            scrollwheel: false,
            panControl: false,
            zoomControlOptions: {
              position: 3
            },
            ...options,
          }}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&language=IT&region=IT&key=${MAPS_API_KEY}`}
          loadingElement={<div className="h-100" />}
          containerElement={<div className="h-100" />}
          mapElement={<div className="h-100" />}
          {...props}
        />
      </div>
    )
  }
}

MapWroom.defaultProps = {
  center: DEFAULT_MAP_CENTER,
}

export default MapWroom
