import React from 'react'
import moment from 'moment'
import get from 'lodash/get'
import './BlockActivityInfo.scss'

const TIMETABLE_MAP = [
  {
    label: 'Lunedì',
    key: 'mon'
  },
  {
    label: 'Martedì',
    key: 'tue'
  },
  {
    label: 'Mercoledì',
    key: 'wed'
  },
  {
    label: 'Giovedì',
    key: 'thu'
  },
  {
    label: 'Venerdì',
    key: 'fri'
  },
  {
    label: 'Sabato',
    key: 'sat'
  },
  {
    label: 'Domenica',
    key: 'sun'
  },
]

const todayKey = moment().format('ddd').toLowerCase()


class BlockActivityInfo extends React.Component {
  render(){
    const partner = this.props.partner
    const timetable = get(partner,'timetable[0].times',{})
    let messageApertura = ''

    if(timetable[todayKey]){

      const startTimeMorning = moment(timetable[todayKey][0]['from'], 'hh:mm:ss')
      const finishTimeMorning = moment(timetable[todayKey][0]['to'], 'hh:mm:ss')

      const startTimeEvening = moment(timetable[todayKey][1]['from'], 'hh:mm:ss')
      const finishTimeEvening = moment(timetable[todayKey][1]['to'], 'hh:mm:ss')

      const now = moment()

      if(now.isBetween(startTimeMorning, finishTimeMorning) || now.isBetween(startTimeEvening, finishTimeEvening)) {
        messageApertura = 'Aperto adesso'
      }
      else {
        messageApertura = 'Chiuso adesso'
      }
    }

    return (
      <div className='BlockActivityInfo container pt-4 text-white main-text pb-4'>
        <div className='row pb-4'>
          <div className='col-md-6'>
            <p>
              <i className="fas fa-map-marker-alt"></i> {partner.location && partner.location.fullAddress}
            </p>
            <p>
              <i className="fas fa-envelope"></i> { partner.emailAddress && partner.emailAddress}
            </p>
            <p>
              <i className="fas fa-phone"></i> {partner.phoneNumber && partner.phoneNumber}
            </p>
            <p>
              {partner.websiteUrl &&
                <a
                  href={partner.websiteUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='btn btn-sm btn-filters'>Sito web
                </a>
              }
            </p>
          </div>
          <div className='col-md-6'>
            <table className='table table-borderless text-white'>
              <tbody>
                <tr>
                  <td colSpan='2'>
                  <img src='/images/apertura-convenzionato.png' alt='Apertura' />{'  '}
                    <b>
                      {messageApertura}
                    </b>
                  </td>
                </tr>
                {TIMETABLE_MAP.map(day => (
                  <tr key={day.key}>
                    <td col='2'>{day.label}</td>
                    <td col='10'>
                      {timetable[day.key][0]['from'] && timetable[day.key][0]['to'] &&
                      timetable[day.key][0]['from']+' - '+timetable[day.key][0]['to']}

                      {timetable[day.key][1]['from'] && timetable[day.key][1]['to'] &&
                      ' / '+timetable[day.key][1]['from']+' - '+timetable[day.key][1]['to']}
                      {!timetable[day.key][0]['from'] && !timetable[day.key][0]['to'] && !timetable[day.key][1]['from'] && !timetable[day.key][1]['to'] &&
                      <b>Giorno di chiusura</b>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default BlockActivityInfo
