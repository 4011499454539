import React from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'

import './BlockEvents.scss'

class BlockEvents extends React.Component {
  render(){
    const events = this.props.events
    return (
      <div className='block-events container pt-4 main-text'>
        <div className='row'>
          {events && events.map((event) =>
          <Fade key={event.id}>
            <div className='col-md-6'>
              <div className='event-block d-flex'
                style={{backgroundImage: `url(${event.mainPicture ?
                  event.mainPicture.thumbnail.medium
                  : '/images/no_image_available.jpeg'})`}}
                >
                <div className="text-white text-center caption">
                  <div><b>{event.title}</b></div>
                  <div className='text-center text-white pt-2 text-word'>
                    {event.description}
                  </div>
                </div>
              </div>
              <div className='text-center mt-3'>
                <Link to={`/eventi/${event.id}`}><button className='btn btn-filters'>Scopri</button></Link>
              </div>
            </div>
          </Fade>
          )}
        </div>
      </div>
    )
  }
}

export default BlockEvents
