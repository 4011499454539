import React from 'react'
import Fade from 'react-reveal/Fade'
import ExhibitorModal from '../ExhibitorModal'
import './BlockExhibitorsEvent.scss'

class BlockExhibitorsEvent extends React.Component {

  state = {
    isOpenPromotionModal : false,
    exhibitor: null
  }

  handlePromotion = (exhibitor) => {
    this.setState({ isOpenPromotionModal: true, exhibitor: exhibitor})
  }

  togglePromotionModal = () => {
    this.setState({ isOpenPromotionModal: !this.state.isOpenPromotionModal})
  }

  render(){
    const exhibitors = this.props.exhibitors
    return (
      <div className='block-exhibitors container pt-4 main-text' style={{minHeight: 500}}>
        <div className='text-center text-white border-bottom font-weight-bold pb-2'>Espositori dell’evento</div>
        <div className='row'>
          {exhibitors && exhibitors.map((exhibitor) =>
          <Fade key={exhibitor.id}>
            <div className='col-md-4'>
              <div
                className='exhibitor-block d-flex pointer'
                onClick={() => this.handlePromotion(exhibitor)}
                style={{backgroundImage: `url(${exhibitor.logo ?
                  exhibitor.logo.thumbnail.medium
                  : '/images/no_image_available.jpeg'})`}}
                >
                <div className="text-white caption text-center">
                  <div><b>{exhibitor.title}</b></div>
                </div>

              </div>
            </div>
          </Fade>
          )}
        </div>
        <ExhibitorModal
          toggle={this.togglePromotionModal}
          isOpen={this.state.isOpenPromotionModal}
          exhibitor={this.state.exhibitor}
        />
      </div>
    )
  }
}

export default BlockExhibitorsEvent
