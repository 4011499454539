import React from 'react'
import Autocomplete from 'react-autocomplete'

import './SearchStations.scss'
import { ICONS_STATIONS, TAG_ICONS } from '../../constants'

class SearchStations extends React.Component {

  state = {
    search : ''
  }

  render(){
    const { stations, mapType } = this.props
    let items = []
    if(this.state.search !== ''){
      items = stations
    }

    items = items.map(item => {
      let icon = mapType === 'zones' ? ICONS_STATIONS['online'] : TAG_ICONS['culture']
      if(mapType === 'zones'){
        if(ICONS_STATIONS[item.state]){
          icon = ICONS_STATIONS[item.state]
        }
      }
      else{
        if(TAG_ICONS[item.tagsKeys[0]]){
          icon = TAG_ICONS[item.tagsKeys[0]]
        }
      }
      return {
        ...item,
        icon: icon
      }
    })



    return (
      <div className='SearchStations position-absolute'>
        <Autocomplete
        getItemValue={(item) => item.name}
        items={items}
        className='search'
        renderItem={(item, isHighlighted) =>
          <div key={item._id}
            style={{
              background: isHighlighted ? 'lightgray' : 'white',
              borderBottom: '1px solid #ccc',
              cursor: 'pointer'
            }}
            className='p-1'>
            <img src={item.icon} alt='map-pointer' className='mr-2' />
            {item.name}
          </div>
        }
        value={this.state.search}
        placeholder="Ricerca"
        menuStyle= {{
          borderBottom: '1px solid #ccc',
          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
          background: 'rgba(255, 255, 255, 0.9)',
          padding: '0px 0px',
          fontSize: '90%',
          position: 'fixed-top',
          overflow: 'auto',
          maxHeight: '50%', // TO
        }}
        onChange={(e) => {
          const search = e.target.value
          this.setState({ search })
          this.props.onSearchChange(search)
        }}
        onSelect={(value,item) => {
          this.props.onSelectCenter(item)
        }}
      />
      </div>
    )
  }
}

export default SearchStations
