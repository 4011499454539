import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { Formik, Field } from 'formik'
import { transformErrorForForm } from '../../../components/Fields/utils'
import { InputField } from '../../../components/Fields'
import * as Yup from 'yup'
import { contactUs } from '../../../state/contacts'

const ContactSchema = Yup.object().shape({
  emailAddress: Yup.string().email().required('E-mail è un campo obbligatorio').label('E-mail'),
  name: Yup.string().required('Nome è un campo obbligatorio').label('Nome'),
  surname: Yup.string().required('Cognome è un campo obbligatorio').label('Cognome'),
  message: Yup.string().required('Messaggio è un campo obbligatorio').label('Messaggio'),
})

class BlockFormContact extends React.Component {

  state = {
    message: ''
  }

  render(){
    
    const { contactUs } = this.props

    return (
      <div className='BlockFormContact pt-4 pb-4 main-text text-white'>
        <div className='container'>
          <div className='text-center'>
            <strong>Richiedi informazioni</strong>
          </div>
          <div className='text-center'>
            Il nostro staff avrà cura di risponderti il più velocemente possibile
          </div>
          <Formik
            initialValues={{
              emailAddress: '',
              name: '',
              surname: '',
              message: '',
            }}
            validationSchema={ContactSchema}
            onSubmit={(values, actions) => {
              actions.setStatus(null)
              return contactUs(values).then(
                ({ data }) => {
                  console.log(data)
                  actions.setSubmitting(false)
                  this.setState({ message: 'Messaggio inviato correttamente. Il nostro staff avrà cura di risponderti il più velocemente possibile'})
                  //actions.setStatus({ msg: 'Messaged sended.' })
                },
                error => {
                  actions.setSubmitting(false)
                  actions.setErrors(transformErrorForForm(error))
                }
              )
            }}
          render={({ handleSubmit, values, isSubmitting, status, errors }) => (
          <form onSubmit={handleSubmit}>
          {this.state.message !== '' &&
            <div className='alert alert-success mt-4'>
              {this.state.message}
            </div>
          }
          <div className='row pt-4'>
            <div className='col-md-6 mx-auto'>
              <Field
                placeholder='E-mail'
                type='text'
                name='emailAddress'
                className='form-control'
                component={InputField}
              />
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-md-3 offset-md-3'>
              <Field
                placeholder='Nome'
                type='text'
                name='name'
                className='form-control'
                component={InputField}
              />
            </div>
            <div className='col-md-3'>
              <Field
                placeholder='Cognome'
                type='text'
                name='surname'
                className='form-control'
                component={InputField}
              />
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-md-6 mx-auto'>
              <Field
                placeholder='Messaggio'
                type='textarea'
                name='message'
                className='form-control'
                rows='10'
                component={InputField}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 mx-auto mt-2'>
            <Button disabled={isSubmitting} className='invia-button' type='submit'> {isSubmitting ? 'Inviando...' : 'Invia'}</Button>
            </div>
          </div>
        </form>
        )}
        />
        </div>
      </div>
    )
  }
}

export default connect(undefined, {
  contactUs,
})(BlockFormContact)
