import React from 'react'

class BlockActivityDescription extends React.Component {
  render(){
    const partner = this.props.partner
    return (
      <div className='BlockActivityDescription main-text bg-white pt-4 pb-4'>
        <div className='container'>
          <h5 className='text-green-gradient'>Chi siamo</h5>
          <div className='text-green-gradient'>
            {partner && partner.description}
          </div>
        </div>
      </div>
    )
  }
}

export default BlockActivityDescription
