import React from 'react'

class BlockVideo extends React.Component {
  render(){
    return (
      <video
        src='https://wroom-prod.s3.eu-west-1.amazonaws.com/resources/wroom.mp4'
        width="100%" height="400" controls
        type="video/mp4">
      </video>      
    )
  }
}

export default BlockVideo
