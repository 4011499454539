import React from 'react';
import TopBar from '../../components/TopBar'
import Footer from '../../components/Footer'
import './eliminautente.css';

const UninstallPage = () => {
  return (
    <div className='main-text'>
      <div className='bg-green-gradient pb-4'>
        <TopBar />
          <div className='bg-white pl-4 pt-4'>
            <div className="uninstall-page">
              <h1>Come Eliminare la tua utenza da Wroom</h1>

              <section>
                <h2>Se desideri rimuovere il tuo account dall'app di Wroom, segui attentamente i seguenti passaggi:</h2>
              </section>

              <section className='pt-4'>
                <h3>Passo 1: Apri l'App</h3>
                <p>Assicurati di avere l'app installata sul tuo dispositivo e aprila. Se non l'hai ancora installata, scaricala dal Play Store (per dispositivi Android) o dall'App Store (per dispositivi iOS).</p>
              </section>

              <section>
                <h3>Passo 2: Accedi al Menu Drawer</h3>
                <p>Una volta aperta l'app, cerca il pulsante del menu in alto a sinistra della schermata. Tocca questo pulsante per aprire il drawer (menu laterale).</p>
              </section>

              <section>
                <h3>Passo 3: Seleziona "Profilo"</h3>
                <p>Nel drawer, troverai diverse opzioni. Cerca e seleziona l'opzione "Profilo".</p>
              </section>

              <section>
                <h3>Passo 4: Vai a "Gestione Profilo"</h3>
                <p>All'interno della sezione Profilo, cerca l'opzione "Gestione Profilo" e selezionala.</p>
              </section>

              <section>
                <h3>Passo 5: Seleziona "Elimina Utente"</h3>
                <p>Nella pagina di Gestione Profilo, troverai l'opzione "Elimina Utente". Premi su questa opzione per procedere con l'eliminazione del tuo account.</p>
              </section>

              <section>
                <h3>Passo 6: Conferma l'Eliminazione</h3>
                <p>L'app ti chiederà di confermare la tua decisione. Questo passaggio è importante, in quanto l'eliminazione dell'account comporterà la perdita di tutti i dati e le informazioni personali associate. Assicurati di essere certo di voler procedere prima di confermare.</p>
              </section>

              <section>
                <h3>Nota Importante</h3>
                <p>Una volta eliminato, non sarà possibile recuperare il tuo account o i dati associati. Assicurati di aver salvato eventuali informazioni importanti prima di procedere con l'eliminazione.</p>
              </section>
            </div>
          </div>
        <Footer />
      </div>
    </div>
  );
};

export default UninstallPage;
