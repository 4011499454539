import React from 'react'
import CookieBanner from 'react-cookie-banner'
import './Cookies.scss'

const styles = {

}
const MESSAGE = 'Questo sito utilizza cookie di profilazione, propri o di terze parti. Navigando nel sito o cliccando su "Accetta" acconsenti all’uso dei cookies.'

class Cookies extends React.Component {
  render(){

    return (
      <CookieBanner
        message={MESSAGE}
        link={<a href='https://wroom.org/cookie-web.html' target="_blank">Maggiori informazioni</a>}
        buttonMessage='Accetta'
        dismissOnScroll={false}
        dismissOnClick={true}
        styles={styles}
        // onAccept={() => setState({ accepted: true })}
      />


    )
  }
}


export default Cookies
